import { Flex, Form, Input, Select, Switch } from "antd";
import React, { useState, useCallback } from "react";
import AddButton from "@/components/common/AddButton";
import debounce from "debounce";
import { videoType } from "@/utils/const";

function VideoFilterForm({ topics, createVideo, onSearch, topic }) {
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearch = useCallback(
    debounce((e) => {
      const value = e.target.value;

      setSearchTerm(value);
      onSearch({ textSearch: value });
    }, 300),
    []
  );

  const handleSearchTerm = (e) => {
    debouncedSearch(e);
  };

  const handleTopicSearchChange = (value) => {
    if (value.length > 0) {
      onSearch({ listTopicId: value });
    } else {
      onSearch({ listTopicId: undefined });
    }
  };

  const changeDisplaySearch = (value) => {
    onSearch({ typeVideoId: value });
  };

  return (
    <Flex direction="column" justify="space-between">
      <Form className="flex flex-wrap gap-3 items-center">
        <Form.Item name="textSearch" className="w-[300px] mb-0">
          <Input
            placeholder="Tìm kiếm video..."
            allowClear
            value={searchTerm}
            onChange={handleSearchTerm}
          />
        </Form.Item>
        <Select
          mode="multiple"
          allowClear
          style={{
            minWidth: "200px",
            maxWidth: "300px",
          }}
          showSearch
          placeholder="Chọn chủ đề"
          onChange={(e) => handleTopicSearchChange(e)}
          filterOption={(input, option) =>
            (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
          }
        >
          {topics.map((status) => (
            <Select.Option key={status.id} value={status.id}>
              {status.topicName}
            </Select.Option>
          ))}
        </Select>

        <Flex align="center">
          <span className="mr-1">Hiển thị video trang chủ</span>
          <Select
            style={{ width: 180 }}
            options={videoType}
            placeholder="Chọn video"
            onChange={changeDisplaySearch}
          />
        </Flex>
      </Form>
      <AddButton text="Thêm video" onClick={createVideo} />
    </Flex>
  );
}

export default VideoFilterForm;
