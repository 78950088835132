import { Flex, Form, Input, Select, message } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import AddButton from "@/components/common/AddButton";
import { useNavigate } from "react-router-dom";
import { getListTopics } from "@/services/base";
import { StatusResponse, listTypes } from "@/utils/const";
import debounce from "debounce";

function NewsFilterForm({ onSearch }) {
  const nav = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [topics, setTopics] = useState([]);

  const debouncedSearch = useCallback(
    debounce((e) => {
      const value = e.target.value;

      setSearchTerm(value);
      onSearch({ textSearch: value });
    }, 300),
    []
  );

  const handleSearchTerm = (e) => {
    debouncedSearch(e);
  };

  useEffect(() => {
    getTopics();
  }, []);

  const getTopics = async () => {
    try {
      const res = await getListTopics({
        typeId: listTypes.NEWS,
        pageIndex: 1,
        pageSize: 100,
      });
      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }

      setTopics(res.data);
    } catch (error) {
      message.error(error);
    }
  };

  const handleTopicSearchChange = (value) => {
    if (value.length > 0) {
      onSearch({ listTopicId: value });
    } else {
      onSearch({ listTopicId: undefined });
    }
  };

  const handleLabelSearchChange = (value) => {
    onSearch({ flagId: value });
  }

  return (
    <Flex direction="column" justify="space-between">
      <Form className="flex flex-wrap gap-3 items-center">
        <Form.Item name="textSearch" className="w-[300px] mb-0">
          <Input
            placeholder="Tìm kiếm tin tức..."
            allowClear
            value={searchTerm}
            onChange={handleSearchTerm}
          />
        </Form.Item>
        <Select
          mode="multiple"
          allowClear
          style={{
            minWidth: "200px",
            maxWidth: "300px",
          }}
          showSearch
          placeholder="Chọn chủ đề"
          onChange={(e) => handleTopicSearchChange(e)}
          filterOption={(input, option) =>
            (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
          }
        >
          {topics.map((status) => (
            <Select.Option key={status.id} value={status.id}>
              {status.topicName}
            </Select.Option>
          ))}
        </Select>
        <Select
          style={{
            minWidth: "200px",
            maxWidth: "300px",
          }}
          allowClear
          placeholder="Chọn nhãn"
          onChange={(e) => handleLabelSearchChange(e)}
        >
          <Select.Option value={1}>Sự kiện đang diễn ra</Select.Option>
          <Select.Option value={2}>Bài viết nổi bật</Select.Option>
        </Select>
        <Select
          style={{
            minWidth: "200px",
            maxWidth: "300px",
          }}
          allowClear
          placeholder="Chọn trạng thái"
          onChange={(e) => onSearch({ isBlock: e })}
        >
          <Select.Option value={0}>Công khai</Select.Option>
          <Select.Option value={1}>Riêng tư</Select.Option>
        </Select>
      </Form>
      <AddButton
        text="Thêm tin tức"
        onClick={() => {
          nav("/news/create");
        }}
      />
    </Flex>
  );
}

export default NewsFilterForm;
