import { getLinkMain, updateLinkMain } from "@/services/base";
import { Form, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";

const ModalEditLinkMain = ({ open, setOpen, reload }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  useEffect(() => {
    if (open) {
      getDomainMain();
    }
  }, [open]);

  const getDomainMain = async () => {
    setLoadingForm(true);
    try {
      const res = await getLinkMain();
      if (res?.code === 200 && res?.error === 0) {
        form.setFieldsValue({
          id: res?.data?.id,
          title: res?.data?.title,
          linkPath: res?.data?.linkPath,
        });
      }
    } catch (err) {
      console.log(err);
      setLoadingForm(false);
    } finally {
      setLoadingForm(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
        const res = await updateLinkMain(values)
        if(res?.code === 200 && res?.error === 0) {
            message.success('Cập nhật thành công!!!')
            setOpen(false)
            await reload()
        }else {
            message.error(res?.message)
        }
        
    } catch (err) {
      console.log(err);
      message.error(err?.message || 'Cập nhật thất bại')
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      onOk={() => form.submit()}
      okButtonProps={{loading: loading, disabled: loading}}
      loading={loadingForm}
    >
      <Form form={form} layout="vertical" autoComplete="off" onFinish={handleSubmit}>
        <Form.Item name={"id"} hidden></Form.Item>
        <Form.Item label="Tiêu đề" name={"title"} rules={[
            {required: true, message: "Tiêu đề không được bỏ trống!!!"}
        ]}>
          <Input placeholder="Nhập tiêu đề..." />
        </Form.Item>
        <Form.Item
          label="Link chính"
          name={"linkPath"}
          rules={[
            {
              required: true,
              message: "Link chính không được bỏ trống!!!",
            },
          ]}
        >
          <Input placeholder="Nhập link chính..." />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalEditLinkMain;
