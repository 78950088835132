import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Upload,
  message,
  Image,
  Tooltip,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  MinusCircleOutlined,
  RetweetOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  createInstruct,
  updateInstruct,
  postFile,
  createImage,
  updateImage,
  getDetailInstruct,
  getListTitleInstruct,
} from "@/services/base";
import VideoThumbnail from "../videos/VideoThumbnail";

const { TextArea } = Input;

const typeDevices = [
  {
    id: 1,
    title: "Điện thoại",
  },
  {
    id: 2,
    title: "Máy tính",
  },
];

const GuideActionForm = ({ modalState, closeModal, reload }) => {
  const [loading, setLoading] = useState(false);
  const { isOpen, title, buttonOk, editData } = modalState;
  const [form] = Form.useForm();
  const [listImage, setListImage] = useState([]);
  const [titleInstructList, setTitleInstructList] = useState([]);

  const [objectUrlVideo, setObjectUrlVideo] = useState(null);
  const [isUrlVideo, setIsUrlVideo] = useState(true);
  const [isChangeUpload, setIsChangeUpload] = useState(false);

  const getTitleInstruct = async () => {
    try {
      const res = await getListTitleInstruct();
      if (res.code !== 200 && res.error !== 0)
        return message.error(res?.message);

      setTitleInstructList(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
      });
    } else {
      setListImage([]);
      form.resetFields();
    }
  }, [isOpen, editData, form]);

  useEffect(() => {
    if (editData?.id) {
      getInstructDetail(editData?.id);
    }

    getTitleInstruct();
    return () => {
      form.resetFields();
    };
  }, [editData?.id]);

  const getInstructDetail = async (id) => {
    try {
      const res = await getDetailInstruct(id);
      if (res.code !== 200 && res.error !== 0)
        return message.error(res?.message);

      setListImage(res.data.images);
      form.setFieldsValue({
        ...res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitSuccess = () => {
    reload();
    form.resetFields();
    closeModal();
    setIsUrlVideo(true);
    setObjectUrlVideo(null);
  };

  const handleCancelModal = () => {
    closeModal();
    form.resetFields();
    setIsUrlVideo(true);
    setObjectUrlVideo(null);
  };

  const onFinish = async (fieldValues) => {
    setLoading(true);

    if (!!editData) {
      let imageIds = [];
      let dataFilePath = editData.videoPath;
      if (isChangeUpload && objectUrlVideo instanceof File) {
        const { data } = await postFile(objectUrlVideo);
        dataFilePath = data.filePath;
      }

      const newListImage = [...listImage];
      const imagesNeedUpload = listImage.filter((e) => e.originFileObj);
      const imagesNeedUpdate = listImage.filter((e) => e.imagePath);

      // Upload image
      if (imagesNeedUpload && imagesNeedUpload.length > 0) {
        for (let index = 0; index < newListImage.length; index++) {
          const element = newListImage[index];

          if (element.originFileObj) {
            const resPostFile = await postFile(element.originFileObj);

            const resCreateImage = await createImage({
              typeId: 7,
              contentImage: newListImage[index].contentImage,
              imagePath: resPostFile?.data?.filePath,
            });

            newListImage[index].id = resCreateImage?.data?.id;
            // return {
            //   ...image,
            //   id: resCreateImage?.data?.id
            // }
          }
        }
        // newListImage?.map(async (image, index) => {

        //   if (image.originFileObj) {
        //     const resPostFile = await postFile(image.originFileObj);

        //     const resCreateImage = await createImage({
        //       typeId: 7,
        //       contentImage: newListImage[index].contentImage,
        //       imagePath: resPostFile?.data?.filePath,
        //     });

        //     return {
        //       ...image,
        //       id: resCreateImage?.data?.id
        //     }
        //   }

        //   return image;
        // });
      }

      // Update image
      if (imagesNeedUpdate && imagesNeedUpdate.length > 0) {
        const imagesNeedUpdatePromises = imagesNeedUpdate.map(
          (resImage, index) => {
            return updateImage({
              ...resImage,
              contentImage: imagesNeedUpdate[index].contentImage,
            });
          }
        );

        await Promise.all(imagesNeedUpdatePromises);
      }

      newListImage.map((e) => imageIds.push(e.id));
      const res = await updateInstruct({
        ...fieldValues,
        images: imageIds,
        videoPath: dataFilePath,
      });

      if (res.code === 200) {
        message.success("cập nhật hướng dẫn thành công");
        handleSubmitSuccess();
      } else {
        message.error(res.message);
      }
    } else {
      let imageIds = [];
      let dataVideoPath;
      if (objectUrlVideo instanceof File) {
        const { data } = await postFile(objectUrlVideo);
        dataVideoPath = data.filePath;
      }

      const attachFilesPromises = listImage?.map((image) => {
        return postFile(image.originFileObj);
      });

      const reponseFilesImage = await Promise.all(attachFilesPromises);

      if (reponseFilesImage && reponseFilesImage.length > 0) {
        const attachImagesPromises = reponseFilesImage.map(
          (resImage, index) => {
            return createImage({
              typeId: 7,
              contentImage: listImage[index].contentImage,
              imagePath: resImage?.data?.filePath,
            });
          }
        );

        const reponseAddImages = await Promise.all(attachImagesPromises);

        if (reponseAddImages && reponseFilesImage.length > 0) {
          reponseAddImages.map((resImage, index) => {
            imageIds.push(resImage?.data?.id);
          });
        }
      }

      const res = await createInstruct({
        ...fieldValues,
        images: imageIds,
        videoPath: dataVideoPath,
      });

      if (res.code === 200) {
        message.success("Thêm hướng dẫn thành công");
        handleSubmitSuccess();
      } else {
        message.error(res.message);
      }
    }

    setLoading(false);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUploadImage = async (file) => {
    const fileImage = file.file;

    if (fileImage.originFileObj) {
      const found = listImage.find(
        (e) =>
          JSON.stringify(e.originFileObj) ===
          JSON.stringify(fileImage.originFileObj)
      );

      if (!found) {
        let base64Image;
        if (fileImage.originFileObj instanceof File) {
          // Get this url from response in real world.
          base64Image = await getBase64(fileImage.originFileObj);
        } else {
          console.error(
            "originFileObj is not a File:",
            fileImage.originFileObj
          );
        }
        const imageObject = {
          id: listImage.length > 0 ? listImage.length + 1 : 0,
          originFileObj: fileImage.originFileObj,
          base64Image,
          contentImage: "",
        };

        const newImages = [...listImage, imageObject];

        setListImage(newImages);
      }
    }
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    console.log(isImage);
    if (!isImage) {
      message.error("Chỉ được tải ảnh lên");
      return Upload.LIST_IGNORE;
    }

    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      message.error("Ảnh upload lên phải bé hơn 3mb");
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const beforeUploadVideo = (file) => {
    const isVideo = file.type.startsWith("video/");
    if (!isVideo) {
      message.error("Chỉ được tải video lên");
      return Upload.LIST_IGNORE;
    }

    const isLt2GB = file.size / 1024 / 1024 < 100;
    if (!isLt2GB) {
      message.error("Video tải lên phải bé hơn 100MB");
      return Upload.LIST_IGNORE;
    }

    if (file) {
      setObjectUrlVideo(file);
      setIsUrlVideo(false);
      setIsChangeUpload(true);
    }

    return false;
  };

  const removeImage = (idImage) => {
    const newImages = listImage.filter((item) => item.id !== idImage);

    setListImage(newImages);
  };

  const changeTextImage = (index, value) => {
    const newImages = [...listImage];
    newImages[index].contentImage = value.target.value;

    setListImage(newImages);
  };

  const handleRepaceImage = async (idImage, file) => {
    const fileImage = file.file;

    if (fileImage.originFileObj) {
      const index = listImage.findIndex((e) => idImage === e.id);

      if (index > -1) {
        let base64Image;
        if (fileImage.originFileObj instanceof File) {
          base64Image = await getBase64(fileImage.originFileObj);
        } else {
          console.error(
            "originFileObj is not a File:",
            fileImage.originFileObj
          );
        }

        const newListImage = listImage.map((image) => {
          if (image.id === idImage) {
            return {
              ...image,
              originFileObj: fileImage.originFileObj,
              base64Image,
              imagePath: null,
            };
          }
          return image;
        });

        setListImage(newListImage);
      }
    }
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      width="600px"
      onCancel={() => handleCancelModal()}
      footer={null}
    >
      <div className="modal-body">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={{ isBlock: false }}
        >
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>

          <Form.Item
            label="Tiêu đề"
            name="title"
            rules={[{ required: true, message: "Vui lòng không để trống." }]}
          >
            <Input placeholder="Nhập tiêu đề của hướng dẫn..." />
          </Form.Item>

          <Form.Item
            label="Nội dung"
            name="contentInstruct"
            rules={[{ required: true, message: "Vui lòng không để trống" }]}
          >
            <Input placeholder="Nhập nội dung hướng dẫn..." />
          </Form.Item>

          <Form.Item label="Hướng dẫn chính" name="titleId">
            <Select placeholder="Chọn hướng dẫn chính">
              {titleInstructList.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Thiết bị" name="type">
            <Select placeholder="Chọn thiết bị">
              {typeDevices.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Video" name="videoPath">
            {isOpen && (
              <VideoThumbnail
                width={100}
                percent={true}
                height={300}
                videoUrl={
                  isUrlVideo && !!editData ? editData.videoPath : objectUrlVideo
                }
                isUrl={isUrlVideo}
              />
            )}
            <div className="mt-1">
              <Upload
                action={false}
                listType="picture"
                maxCount={1}
                showUploadList={false}
                beforeUpload={beforeUploadVideo}
              >
                <Button icon={<UploadOutlined />}>Tải video lên</Button>
              </Upload>
            </div>
          </Form.Item>

          {listImage.map((item, index) => {
            return (
              <div className="flex gap-2 mb-2" key={index}>
                <div className="w-[146px] h-[146px] flex items-center justify-center flex-shrink-0 px-2 py-2 border-solid border-[1px] border-[#d9d9d9] rounded-lg">
                  <Image
                    style={{
                      maxHeight: "140px",
                    }}
                    src={
                      item.imagePath
                        ? `${process.env.REACT_APP_FILE}/${item.imagePath}`
                        : item.base64Image
                    }
                  />
                </div>
                <TextArea
                  defaultValue={item.contentImage}
                  className="w-full h-full"
                  placeholder="Nhập miêu tả hình ảnh..."
                  onChange={(value) => changeTextImage(index, value)}
                />
                <div className="flex flex-col items-center justify-center gap-2 w-[50px]">
                  <Tooltip title={"Xóa hình ảnh"}>
                    <MinusCircleOutlined onClick={() => removeImage(item.id)} />
                  </Tooltip>
                  <Tooltip title={"Thay thế hình ảnh"}>
                    <Upload
                      onChange={(file) => handleRepaceImage(item.id, file)}
                      maxCount={1}
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                    >
                      <RetweetOutlined className="cursor-pointer" />
                    </Upload>
                  </Tooltip>
                </div>
              </div>
            );
          })}
          <Form.Item label="Hình ảnh">
            <Upload
              action={""}
              listType="picture"
              onChange={(file) => handleUploadImage(file)}
              maxCount={1}
              showUploadList={false}
              beforeUpload={beforeUpload}
              className="w-[200px]"
            >
              <Button icon={<PlusOutlined />}>Thêm hình ảnh</Button>
            </Upload>
          </Form.Item>

          <Row justify="end">
            <div className="relative">
              <Button
                type="primary"
                htmlType="submit"
                className="w-[105px] h-[38px] inline-flex justify-center items-center px-[24px] py-[10px] text-[16px] font-semibold"
              >
                {buttonOk}
              </Button>
              {loading && (
                <div className="absolute top-0 left-0 w-full h-full bg-white opacity-35 z-10 flex items-center justify-center">
                  <LoadingOutlined
                    style={{
                      fontSize: 30,
                    }}
                  />
                </div>
              )}
            </div>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default GuideActionForm;
