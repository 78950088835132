import React, { useCallback, useEffect, useState } from "react";
import { Button, Divider, Image, message, Modal, Tooltip, Tag } from "antd";
import DataTable from "@/components/common/DataTable";
import moment from "moment";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import LinkFilterForm from "@/components/links/LinkFilterForm";
import LinkActionForm from "@/components/links/LinkActionForm";
import TotalCount from "@/components/common/TotalCount";
import { StatusResponse, pageCMS } from "@/utils/const";
import { getListTypes, deleteLink, getListLinks } from "@/services/base";
import debounce from "debounce";
import ModalEditLinkMain from "@/components/links/ModalEditLinkMain";

const Links = () => {
  document.title = "Quản lý link";

  const [loading, setLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [typePage, setTypePage] = useState([]);
  const [messageUpload, setMessageUpload] = useState("");
  const [modalState, setModalState] = useState({
    isOpen: false,
    isCreate: true,
    title: "Thêm mới link",
    buttonOk: "Thêm mới",
    editData: null,
  });
  const [open, setOpen] = useState(false);

  const [params, setParams] = useState({
    textSearch: "",
    pageIndex: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    getTopics();
  }, []);

  const getTopics = async () => {
    try {
      const res = await getListTypes({ cMSPageId: pageCMS[4].id, pageIndex: null, pageSize: null });
      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }

      setTypePage(res.data);
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    getImages();
  }, [params]);

  const getImages = async () => {
    try {
      setLoading(true);
      const res = await getListLinks({ ...params });

      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }

      setTotalPage(res?.pagination?.total);
      setVideoList(res.data);
      setLoading(false);
    } catch (error) {
      message.error(error);
      setLoading(false);
    }
  };

  const onSearch = (value) => {
    setParams((prev) => ({
      ...prev,
      ...value,
      pageIndex: 1,
    }));
  };

  const reloadData = () => {
    getImages();
  };

  const handleEdit = (record) => {
    setModalState({
      isOpen: true,
      isCreate: false,
      buttonOk: "Cập nhật",
      editData: {
        ...record,
      },
      title: "Chỉnh sửa link",
    });
  };

  const handleDelete = async (record) => {
    Modal.confirm({
      title: "Xác nhận xóa",
      content: `Bạn có chắc chắn muốn xóa đường dẫn này"?`,
      okText: "Xóa",
      okType: "danger",
      cancelText: "Hủy",
      onOk: async () => {
        try {
          setLoading(true);
          const res = await deleteLink(record.id);
          if (!res.isError) {
            message.success("Xóa đường dẫn thành công");
            reloadData();
          } else {
            message.error("Xóa đường dẫn thất bại");
          }
        } catch (error) {
          message.error("Error deleting link.");
          console.error("Error deleting link:", error);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const onchangePage = (page, pageSize) => {
    setParams((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize,
    }));
  };

  const handleCloseModal = () => {
    setModalState({
      isOpen: false,
      isCreate: true,
      title: "Thêm mới link",
      buttonOk: "Thêm mới",
      editData: null,
    });
    setMessageUpload("");
  };

  const columns = [
    {
      title: "Logo",
      dataIndex: "logoPath",
      width: 100,
      render: (value) => {
        return value ? (
          <Image width={100} src={process.env.REACT_APP_FILE + "/" + value} />
        ) : (
          "--"
        );
      },
    },
    {
      title: "Hình nền",
      dataIndex: "backgroundPath",
      width: 100,
      render: (value) => {
        return value ? (
          <Image width={100} src={process.env.REACT_APP_FILE + "/" + value} />
        ) : (
          "--"
        );
      },
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
    },
    {
      title: "Đường dẫn",
      dataIndex: "linkPath",
    },
    {
      title: "Chủ đề",
      dataIndex: "type",
      render: (value) => {
        return value
          ? (<Tag>{value?.typeName}</Tag>)
          : "";
      }
    },
    {
      title: "Thời gian tạo",
      dataIndex: "createdDate",
      render: (value) => moment(value + "z").format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Thời gian cập nhật",
      dataIndex: "modifiedDate",
      render: (value) => moment(value + "z").format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) => {
        return (
          <div className="flex gap-2">
            <Tooltip title={"Chỉnh sửa"}>
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => handleEdit(record)}
              />
            </Tooltip>
            <Tooltip title={"Xóa"}>
              <Button
                type="primary"
                shape="circle"
                danger
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => handleDelete(record)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <LinkFilterForm
        typePage={typePage}
        onSearch={onSearch}
        setOpen={setOpen}
        createVideo={() =>
          setModalState({
            isOpen: true,
            isCreate: true,
            buttonOk: "Thêm mới",
            editData: null,
            title: "Thêm link",
          })
        }
      />
      <TotalCount
        text="link"
        loading={loading}
        total={totalPage ? totalPage : 0}
      />
      <Divider />
      <DataTable
        columns={columns}
        dataSource={videoList.map((u) => ({ ...u, key: u.id })) || []}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={totalPage}
        loading={loading}
        onPageChange={onchangePage}
      />
      <div></div>
      <div></div>
      <LinkActionForm
        typePage={typePage}
        modalState={modalState}
        closeModal={() => handleCloseModal()}
        reload={reloadData}
        messageUpload={messageUpload}
        setMessageUpload={setMessageUpload}
      />
      <ModalEditLinkMain open={open} setOpen={setOpen} reload={getImages} />
    </div>
  );
};
export default Links;
