import {
  Button,
  Select,
  Form,
  Input,
  Modal,
  Row,
  Upload,
  message,
  Image,
} from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { createLink, updateLink, postFile } from "@/services/base";

const LinkActionForm = ({
  typePage,
  modalState,
  closeModal,
  reload,
  messageUpload,
  setMessageUpload,
}) => {
  const { isOpen, title, buttonOk } = modalState;
  const [editData, setEditData] = useState();
  const [form] = Form.useForm();
  const [objectUrlImage, setObjectUrlImage] = useState(null);
  const [objectUrlBackground, setObjectUrlBackround] = useState();
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileListLogo, setFileListLogo] = useState();
  const [fileListBackground, setfileListBackground] = useState();

  useEffect(() => {
    setEditData(modalState?.editData);
  }, [modalState]);
  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
      });

      if (editData?.backgroundPath) {
        setfileListBackground([
          {
            uid: "-1",
            name: "",
            status: "done",
            url: `${process.env.REACT_APP_FILE}/${editData.backgroundPath}`,
          },
        ]);
      } else {
        setfileListBackground();
      }

      if (editData?.logoPath) {
        setFileListLogo([
          {
            uid: "-1",
            name: "",
            status: "done",
            url: `${process.env.REACT_APP_FILE}/${editData.logoPath}`,
          },
        ]);
      } else {
        setFileListLogo();
      }
    } else {
      form.resetFields();
      setFileListLogo();
      setfileListBackground();
    }
  }, [isOpen, editData, form]);

  const handleSubmitSuccess = () => {
    reload();
    form.resetFields();
    closeModal();
    setObjectUrlImage(null);
  };

  const handleCancelModal = () => {
    closeModal();
    form.resetFields();
    setObjectUrlImage(null);
  };

  const onFinish = async (fieldValues) => {
    let data = null;
    let dataResonseBg = null;

    if (objectUrlImage) {
      const response = await postFile(objectUrlImage);
      data = response.data;
    }

    if (objectUrlBackground) {
      const response = await postFile(objectUrlBackground);
      dataResonseBg = response.data;
    }

    if (!!editData) {
      const res = await updateLink({
        ...fieldValues,
        logoPath: data ? data.filePath : editData.logoPath,
        backgroundPath: dataResonseBg
          ? dataResonseBg.filePath
          : editData.backgroundPath,
      });

      if (res.code === 200) {
        message.success("cập nhật đường dẫn thành công");
        handleSubmitSuccess();
      } else {
        message.error(res.message);
      }
    } else {
      const res = await createLink({
        ...fieldValues,
        logoPath: data?.filePath || "",
        backgroundPath: dataResonseBg?.filePath || "",
      });

      if (res.code === 200) {
        message.success("Thêm đường dẫn thành công");
        handleSubmitSuccess();
      } else {
        message.error(res.message);
      }
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Tải lên</div>
    </div>
  );

  const beforeUploadLogo = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      setMessageUpload("Chỉ được tải hình ảnh lên");
      return Upload.LIST_IGNORE;
    }

    const isLt5MB = file.size / 1024 / 1024 < 5;
    if (!isLt5MB) {
      setMessageUpload("Hình ảnh tải lên phải bé hơn 5MB");
      return Upload.LIST_IGNORE;
    }

    setObjectUrlImage(file);
    setFileListLogo()

    return false;
  };

  const beforeUploadBackground = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      setMessageUpload("Chỉ được tải hình ảnh lên");
      return Upload.LIST_IGNORE;
    }

    const isLt5MB = file.size / 1024 / 1024 < 5;
    if (!isLt5MB) {
      setMessageUpload("Hình ảnh tải lên phải bé hơn 5MB");
      return Upload.LIST_IGNORE;
    }

    setObjectUrlBackround(file);
    setfileListBackground();

    return false;
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleRemoveLogo = () => {
    setFileListLogo();
    setObjectUrlImage();

    setEditData((prev) => ({
      ...prev,
      logoPath: null,
    }));
  };

  const handleRemoveBackground = () => {
    setfileListBackground();
    setObjectUrlBackround();

    setEditData((prev) => ({
      ...prev,
      backgroundPath: null,
    }));
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      width="600px"
      onCancel={() => handleCancelModal()}
      footer={null}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ isBlock: false }}
      >
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label="Tiêu đề"
          name="title"
          rules={[{ required: true, message: "Vui lòng không để trống." }]}
        >
          <Input placeholder="Nhập tiêu đề..." />
        </Form.Item>
        <Form.Item
          label="Đường dẫn"
          name="linkPath"
          rules={[{ required: true, message: "Vui lòng không để trống." }]}
        >
          <Input placeholder="Nhập đường dẫn..." />
        </Form.Item>
        <Form.Item
          label="Chọn thể loại"
          name={["typeId"]}
          rules={[{ required: true, message: "Vui lòng chọn thể loại" }]}
        >
          <Select
            allowClear
            showSearch
            placeholder="Chọn thể loại"
            filterOption={(input, option) =>
              (option?.children ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {typePage?.map((type) => (
              <Select.Option key={type.id} value={type.id}>
                {type.typeName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className="flex gap-8">
          <Form.Item label="Logo" name="logo">
            <Upload
              listType="picture-card"
              maxCount={1}
              fileList={fileListLogo}
              onPreview={handlePreview}
              onRemove={handleRemoveLogo}
              beforeUpload={beforeUploadLogo}
            >
              {uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item label="Hình nền" name="background">
            {previewImage && (
              <Image
                wrapperStyle={{ display: "none" }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
                src={previewImage}
              />
            )}
            <Upload
              listType="picture-card"
              maxCount={1}
              fileList={fileListBackground}
              onPreview={handlePreview}
              onRemove={handleRemoveBackground}
              beforeUpload={beforeUploadBackground}
            >
              {uploadButton}
            </Upload>
          </Form.Item>
        </div>
        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            className="w-[105px] h-[38px] inline-flex justify-center items-center px-[24px] py-[10px] text-[16px] font-semibold"
          >
            {buttonOk}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default LinkActionForm;
