import { selector } from "recoil";
import { getAccessTokenBase, getUserInfo } from "@/utils/common";
import { StatusResponse } from "@/utils/const";
import { getUserById } from "@/services/base";

export const selectorUserInfo = selector({
  key: "selectorUserInfo",
  get: async () => {
    const accessToken = getAccessTokenBase();
    let userInfo = getUserInfo();
    if (!accessToken || !userInfo) return;

    userInfo = JSON.parse(userInfo);
    const res = await getUserById(userInfo.id);

    if (res?.code !== StatusResponse.SUCCESS)
      return localStorage.removeItem("access_token");
    return res?.data;
  },
});
