import {
  Button,
  Select,
  Form,
  Input,
  Modal,
  Row,
  Upload,
  message,
  Divider,
  Image,
  Flex,
  Switch,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import {
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { createVideo, updateVideo, postFile } from "@/services/base";
import { videoType } from "@/utils/const";

const VideoActionForm = ({ topics, modalState, closeModal, reload }) => {
  const { isOpen, isCreate, title, buttonOk } = modalState;
  const [form] = Form.useForm();
  const [editData, setEditData] = useState();
  const [objectUrlVideo, setObjectUrlVideo] = useState(null);
  const [messageUpload, setMessageUpload] = useState("");
  const [isUrlVideo, setIsUrlVideo] = useState(true);
  const [isChangeUpload, setIsChangeUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [objectUrlBackround, setObjectUrlBackround] = useState(null);
  const [urlBackground, setUrlBackground] = useState("");
  const [objectUrlLogo, setObjectUrlLogo] = useState(null);
  const [urlLogo, setUrlLogo] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileListLogo, setFileListLogo] = useState();
  const [fileListBackground, setfileListBackground] = useState();
  const refVideo = useRef(null);

  useEffect(() => {
    setMessageUpload("");
    setEditData(modalState?.editData);

    if (refVideo && refVideo.current) {
      refVideo.current.pause();
    }
  }, [modalState]);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
      });

      setObjectUrlVideo(editData.videoPath);

      if (editData?.avatarPath) {
        setfileListBackground([
          {
            uid: "-1",
            name: "",
            status: "done",
            url: `${process.env.REACT_APP_FILE}/${editData.avatarPath}`,
          },
        ]);
      } else {
        setfileListBackground();
      }

      if (editData?.logoPath) {
        setFileListLogo([
          {
            uid: "-1",
            name: "",
            status: "done",
            url: `${process.env.REACT_APP_FILE}/${editData.logoPath}`,
          },
        ]);
      } else {
        setFileListLogo();
      }
    } else {
      form.resetFields();
      setFileListLogo();
      setfileListBackground();
      setObjectUrlVideo();
    }
  }, [isOpen, editData, form]);

  const handleSubmitSuccess = () => {
    reload();
    form.resetFields();
    closeModal();
    setIsUrlVideo(true);
    setObjectUrlVideo(null);
  };

  const handleCancelModal = () => {
    closeModal();
    form.resetFields();
    setIsUrlVideo(true);
    setObjectUrlVideo(null);
    setObjectUrlBackround(null);
  };

  const onFinish = async (fieldValues) => {
    if (!objectUrlVideo) {
      setMessageUpload("Vui lòng tải video lên");
      return;
    }

    try {
      setLoading(true);
      if (!isCreate) {
        let dataFilePath = editData.videoPath;
        let objectPath = {};
        if (isChangeUpload && objectUrlVideo instanceof File) {
          const { data } = await postFile(objectUrlVideo);
          dataFilePath = data.filePath;
        }

        if (objectUrlBackround) {
          const responseBackground = await postFile(objectUrlBackround);

          if (responseBackground?.data?.filePath) {
            objectPath = {
              avatarPath: responseBackground.data.filePath,
            };
          }
        }

        if (objectUrlLogo) {
          const responseLogo = await postFile(objectUrlLogo);

          if (responseLogo?.data?.filePath) {
            objectPath = {
              ...objectPath,
              logoPath: responseLogo.data.filePath,
            };
          }
        }

        const res = await updateVideo({
          ...fieldValues,
          videoPath: dataFilePath,
          logoPath: editData?.logoPath,
          avatarPath: editData?.avatarPath,
          ...objectPath,
        });

        if (res.code === 200) {
          message.success("cập nhật video thành công");
          handleSubmitSuccess();
        } else {
          message.error(res.message);
        }
      } else {
        const listPostFile = [
          objectUrlVideo,
          objectUrlBackround,
          objectUrlLogo,
        ].map((e) => postFile(e));
        const [responVideo, responseBackground, responseLogo] =
          await Promise.all(listPostFile);

        const res = await createVideo({
          ...fieldValues,
          videoPath: responVideo?.data?.filePath,
          avatarPath: responseBackground?.data?.filePath,
          logoPath: responseLogo?.data?.filePath,
        });

        if (res.code === 200) {
          message.success("Thêm video thành công");
          handleSubmitSuccess();
        } else {
          message.error(res.message);
        }
      }
      setObjectUrlBackround(null);
      setfileListBackground();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadVideo = (file) => {
    const fileVideo = file.file;

    if (fileVideo.originFileObj) {
      setObjectUrlVideo(fileVideo.originFileObj);
      setIsUrlVideo(false);
      setIsChangeUpload(true);
    }
  };

  const beforeUpload = (file) => {
    const isVideo = file.type.startsWith("video/");
    if (!isVideo) {
      message.error("Chỉ được tải video lên");
      return Upload.LIST_IGNORE;
    }

    const isLt2GB = file.size / 1024 / 1024 < 100;
    if (!isLt2GB) {
      message.error("Video tải lên phải bé hơn 100MB");
      return Upload.LIST_IGNORE;
    }

    if (file) {
      setObjectUrlVideo(file);
      setIsUrlVideo(false);
      setIsChangeUpload(true);
    }

    return false;
  };

  const beforeUploadBackground = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("Chỉ được tải hình ảnh lên");
      return Upload.LIST_IGNORE;
    }

    const isLt2GB = file.size / 1024 / 1024 < 2;
    if (!isLt2GB) {
      message.error("Hình ảnh tải lên phải bé hơn 2MB");
      return Upload.LIST_IGNORE;
    }

    setObjectUrlBackround(file);
    setfileListBackground();
    setEditData((prev) => ({
      ...prev,
      avatarPath: null,
    }));

    return false;
  };

  const beforeUploadLogo = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("Chỉ được tải hình ảnh lên");
      return Upload.LIST_IGNORE;
    }

    const isLt2GB = file.size / 1024 / 1024 < 2;
    if (!isLt2GB) {
      message.error("Hình ảnh tải lên phải bé hơn 2MB");
      return Upload.LIST_IGNORE;
    }

    setObjectUrlLogo(file);
    setFileListLogo();
    setEditData((prev) => ({
      ...prev,
      logoPath: null,
    }));

    return false;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Tải lên</div>
    </div>
  );

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleRemoveLogo = () => {
    setFileListLogo();
    setObjectUrlLogo();

    setEditData((prev) => ({
      ...prev,
      logoPath: null,
    }));
  };

  const handleRemoveBackground = () => {
    setfileListBackground();
    setObjectUrlBackround();

    setEditData((prev) => ({
      ...prev,
      avatarPath: null,
    }));
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      width="600px"
      onCancel={() => handleCancelModal()}
      footer={null}
    >
      <div className="modal-body">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={{ isBlock: false, typeVideoId: videoType[0].value }}
        >
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item
            label="Tiêu đề"
            name="title"
            rules={[{ required: true, message: "Vui lòng không để trống." }]}
          >
            <Input placeholder="Nhập tiêu đề của video..." />
          </Form.Item>
          <Form.Item label="Chọn chủ đề" name={["topics"]}>
            <Select
              mode="multiple"
              allowClear
              showSearch
              placeholder="Chọn chủ đề"
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {topics?.map((topic) => (
                <Select.Option key={topic.id} value={topic.id}>
                  {topic.topicName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="flex gap-8">
            {previewImage && (
              <Image
                wrapperStyle={{ display: "none" }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
                src={previewImage}
              />
            )}
            <Form.Item label="Hình nền" name="background">
              <Upload
                listType="picture-card"
                maxCount={1}
                fileList={fileListBackground}
                onPreview={handlePreview}
                onRemove={handleRemoveBackground}
                beforeUpload={beforeUploadBackground}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item label="Logo" name="logo">
              <Upload
                listType="picture-card"
                maxCount={1}
                fileList={fileListLogo}
                onPreview={handlePreview}
                onRemove={handleRemoveLogo}
                beforeUpload={beforeUploadLogo}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
          </div>
          <Form.Item label="Video">
            {(objectUrlVideo || editData?.videoPath) && (
              <video
                ref={refVideo}
                width={300}
                controls
                src={
                  objectUrlVideo instanceof File
                    ? URL.createObjectURL(objectUrlVideo)
                    : `${process.env.REACT_APP_FILE}/${editData?.videoPath}`
                }
              ></video>
            )}
            <div className="mt-1">
              <Upload
                action={false}
                listType="picture"
                // onChange={handleUploadVideo}
                maxCount={1}
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                <Button icon={<UploadOutlined />}>Tải video lên</Button>
              </Upload>
            </div>
            <div className="text-[#ff4d4f] text-sm mt-1">{messageUpload}</div>
          </Form.Item>
          {/* <Form.Item label="Hiển thị ở trang chủ." name="isBackground">
            <Switch />
          </Form.Item> */}
          <Form.Item
            label="Hiển thị video trang chủ hoặc video giới thiệu"
            name="typeVideoId"
          >
            <Select style={{ width: 220 }} options={videoType} allowClear />
          </Form.Item>
          <p className="ml-1">
            <b>*Lưu ý:</b> Nếu chọn hiển thị ở trang chủ thì chủ đề của video sẽ
            bị xóa đi, và video này sẽ không hiển thị ở trang Video nữa.
          </p>
          <Row justify="end">
            <div className="relative">
              <Button
                type="primary"
                htmlType="submit"
                className="w-[105px] h-[38px] inline-flex justify-center items-center px-[24px] py-[10px] text-[16px] font-semibold"
              >
                {buttonOk}
              </Button>
              {loading && (
                <div className="absolute top-0 left-0 w-full h-full bg-white opacity-35 z-10 flex items-center justify-center">
                  <LoadingOutlined
                    style={{
                      fontSize: 30,
                    }}
                  />
                </div>
              )}
            </div>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default VideoActionForm;
