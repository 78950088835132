import { Flex, Form, Input } from "antd";
import React, { useState, useEffect, useRef } from "react";
import AddButton from "@/components/common/AddButton";
import { durationTimeFormat } from "@/utils/common";
import {
  StarOutlined,
  StarFilled,
  PlayCircleOutlined,
} from "@ant-design/icons";

function VideoThumbnail({ videoUrl, isUrl, width, height, percent }) {
  const refVideo = useRef();
  const refImg = useRef();
  const [blob, setBlob] = useState(null);
  const [isPlay, setIsPlay] = useState(false);
  const [duration, setDuration] = useState("");
  const [imgElement, setImgElement] = useState(null);

  const fetVideoFile = async (videoUrl) => {
    try {
      const response = await fetch(videoUrl);
      const data = await response.blob();

      if (data.type !== "application/json") {
        setBlob(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (imgElement && refImg.current && refImg.current.childNodes.length > 1) {
      refImg.current.removeChild(imgElement);
      setImgElement(null);
    }

    setIsPlay(false);

    if (!isUrl) {
      setBlob(videoUrl);
    } else if (videoUrl && videoUrl.includes("uploads")) {
      const videoFullPath = `${process.env.REACT_APP_FILE}/${videoUrl}`;
      fetVideoFile(videoFullPath);
    }
  }, [videoUrl]);

  useEffect(() => {
    if (blob) {
      const url = URL.createObjectURL(blob);
      const video = document.createElement("video");
      const timeupdate = function () {
        if (snapImage()) {
          video.removeEventListener("timeupdate", timeupdate);
          video.pause();
        }
      };
      video.addEventListener("loadeddata", function () {
        const durationFormat = durationTimeFormat(video.duration);
        setDuration(durationFormat);
        if (snapImage()) {
          video.removeEventListener("timeupdate", timeupdate);
        }
      });
      const snapImage = function () {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, canvas.width, canvas.height);
        const image = canvas.toDataURL();
        const success = image.length > 100000;
        if (success) {
          const img = document.createElement("img");
          img.src = image;
          img.style.width = "100%";
          img.style.height = "100%";
          img.style.objectFit = "cover";

          if (refImg.current) {
            refImg.current.appendChild(img);

            setImgElement(img);
          }
        }

        return success;
      };
      video.addEventListener("timeupdate", timeupdate);
      video.preload = "metadata";
      video.src = url;
      // Load video in Safari / IE11
      video.muted = true;
      video.playsInline = true;
      video.play();
    }
  }, [blob]);

  useEffect(() => {
    if (isPlay) {
      if (refVideo.current) {
        refVideo.current.play();
      }
    }
  }, [isPlay]);

  const clickPlayVideo = () => {
    setIsPlay(true);
  };

  return (
    <div>
      {!isPlay && videoUrl && (
        <div
          ref={refImg}
          style={{
            width: width ? `${width}${percent ? "%" : "px"}` : "150px",
            height: height ? `${height}px` : "150px",
          }}
          className="rounded-md border-solid border-[2px] border-[#ff9900] relative"
        >
          <div
            className="absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)] text-white flex items-center justify-center text-[50px] cursor-pointer"
            onClick={() => clickPlayVideo()}
          >
            <PlayCircleOutlined />
            <span className="absolute bottom-3 right-3 text-sm">
              {duration}
            </span>
          </div>
        </div>
      )}
      {isPlay && (
        <div
          style={{
            width: width ? `${width}${percent ? "%" : "px"}` : "150px",
            height: height ? `${height}px` : "150px",
          }}
          className="rounded-md border-solid border-[2px] border-[#ff9900] flex"
        >
          <video
            style={{
              width: width
                ? `${percent ? `${width}%` : `${width - 4}px`}`
                : "146px",
              height: height ? `${height - 4}px` : "146px",
            }}
            controls
            ref={refVideo}
            src={
              isUrl
                ? `${process.env.REACT_APP_FILE}/${videoUrl}`
                : URL.createObjectURL(blob)
            }
          ></video>
        </div>
      )}
    </div>
  );
}

export default VideoThumbnail;
