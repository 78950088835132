import React, { useCallback, useEffect, useState } from "react";
import { Button, Divider, message, Modal, Tooltip, Tag, Image } from "antd";
import DataTable from "@/components/common/DataTable";
import moment from "moment";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import VideoFilterForm from "@/components/videos/VideoFilterForm";
import VideoActionForm from "@/components/videos/VideoActionForm";
import TotalCount from "@/components/common/TotalCount";
import VideoThumbnail from "@/components/videos/VideoThumbnail";
import { StatusResponse, listTypes } from "@/utils/const";
import { getListTopics, getListVideos, deleteHardVideo } from "@/services/base";
import qs from "query-string";

const Images = () => {
  document.title = "Quản lý video";

  const [loading, setLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [topics, setTopics] = useState([]);
  const [modalState, setModalState] = useState({
    isOpen: false,
    isCreate: true,
    title: "Thêm mới video",
    buttonOk: "Thêm mới",
    editData: null,
  });
  const [params, setParams] = useState({
    textSearch: "",
    pageIndex: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    getTopics();
  }, []);

  const getTopics = async () => {
    try {
      const res = await getListTopics({
        typeId: listTypes.VIDEO,
        pageIndex: 1,
        pageSize: 100,
      });
      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }

      setTopics(res.data);
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    getVideos();
  }, [params]);

  const getVideos = async () => {
    try {
      setLoading(true);
      const data = qs.stringify(params);
      const res = await getListVideos(data);

      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }

      setTotalPage(res?.pagination?.total);
      setVideoList(res.data);
      setLoading(false);
    } catch (error) {
      message.error(error);
      setLoading(false);
    }
  };

  const onSearch = (value) => {
    setParams((prev) => ({
      ...prev,
      ...value,
      pageIndex: 1,
    }));
  };

  const reloadData = () => {
    getVideos();
  };

  const handleEdit = (record) => {
    setModalState({
      isOpen: true,
      isCreate: false,
      buttonOk: "Cập nhật",
      editData: {
        ...record,
        topics:
          record?.topics?.length > 0
            ? record.topics.map((item) => item.id)
            : [],
      },
      title: "Chỉnh sửa video",
    });
  };

  const handleDelete = async (record) => {
    Modal.confirm({
      title: "Xác nhận xóa",
      content: `Bạn có chắc chắn muốn xóa video này"?`,
      okText: "Xóa",
      okType: "danger",
      cancelText: "Hủy",
      onOk: async () => {
        try {
          setLoading(true);
          const res = await deleteHardVideo(record.id);
          if (!res.isError) {
            message.success("Xóa video thành công");
            reloadData();
          } else {
            message.error("Xóa video thất bại");
          }
        } catch (error) {
          message.error("Error deleting video.");
          console.error("Error deleting video:", error);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const onchangePage = (page, pageSize) => {
    setParams((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize,
    }));
  };

  const handleCloseModal = () => {
    setModalState({
      isOpen: false,
      isCreate: true,
      title: "Thêm mới video",
      buttonOk: "Thêm mới",
      editData: null,
    });
  };

  const columns = [
    // {
    //   title: "Video",
    //   dataIndex: "videoPath",
    //   width: 100,
    //   render: (videoPath) => {
    //     if (videoPath && videoPath.includes("uploads")) {
    //       return (
    //         <div className="rounded-md">
    //           <VideoThumbnail videoUrl={videoPath} isUrl={true} />
    //         </div>
    //       );
    //     }

    //     return null;
    //   },
    // },
    {
      title: "Hình nền",
      dataIndex: "avatarPath",
      width: 150,
      render: (value) => {
        return value ? (
          <Image width={100} src={process.env.REACT_APP_FILE + "/" + value} />
        ) : (
          "--"
        );
      },
    },
    {
      title: "Logo",
      dataIndex: "logoPath",
      width: 150,
      render: (value) => {
        return value ? (
          <Image width={100} src={process.env.REACT_APP_FILE + "/" + value} />
        ) : (
          "--"
        );
      },
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
    },
    {
      title: "Chủ đề",
      dataIndex: "topics",
      render: (value) => {
        return value && value.length > 0
          ? value.map((item) => <Tag>{item.topicName}</Tag>)
          : "";
      },
    },
    {
      title: "Nơi hiển thị",
      dataIndex: "isBackground",
      render: (value) => {
        return <Tag>{value ? 'Trang chủ' : 'Video'}</Tag>
      },
    },
    {
      title: "Thời gian tạo",
      dataIndex: "createdDate",
      render: (value) => moment(value + "z").format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Thời gian cập nhật",
      dataIndex: "modifiedDate",
      render: (value) => moment(value + "z").format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) => {
        return (
          <div className="flex gap-2">
            <Tooltip title={"Chỉnh sửa"}>
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => handleEdit(record)}
              />
            </Tooltip>
            <Tooltip title={"Xóa"}>
              <Button
                type="primary"
                shape="circle"
                danger
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => handleDelete(record)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <VideoFilterForm
        topics={topics}
        onSearch={onSearch}
        createVideo={() =>
          setModalState({
            isOpen: true,
            isCreate: true,
            buttonOk: "Thêm mới",
            editData: null,
            title: "Thêm video",
          })
        }
      />
      <TotalCount
        text="videos"
        loading={loading}
        total={totalPage ? totalPage : 0}
      />
      <Divider />
      <DataTable
        columns={columns}
        dataSource={videoList.map((u) => ({ ...u, key: u.id })) || []}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={totalPage}
        loading={loading}
        onPageChange={onchangePage}
      />
      <VideoActionForm
        topics={topics}
        modalState={modalState}
        closeModal={() => handleCloseModal()}
        reload={reloadData}
      />
    </div>
  );
};
export default Images;
