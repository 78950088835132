import React, { useState } from "react";
import { Avatar, Dropdown, Space } from "antd";
import { DownOutlined, LockOutlined, PoweroffOutlined, UpOutlined, UserOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { infoUser } from "@/recoil/atoms";
import { routes } from "@/utils/const";
import ChangePassword from "@/page/UserProfile/ChangePassword";

const Header = ({ collapsed, setCollapsed }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const [userInfor, setUserInfo] = useRecoilState(infoUser);
  const [visible, setVisible] = useState(false);

  const { userName, fullName } = userInfor;

  const onClose = () => {
    setVisible(false);
  };

  const items = [
    {
      label: "Thông tin cá nhân",
      key: "1",
      icon: <UserOutlined />,
      onClick: () => {
        navigate(routes.USER_PROFILE);
      },
    },
    {
      label: "Đổi mật khẩu",
      key: "2",
      icon: <LockOutlined />,
      onClick: () => setVisible(true),
    },
    {
      label: "Đăng xuất",
      key: "3",
      icon: <PoweroffOutlined />,
      onClick: () => {
        localStorage.clear();
        setUserInfo(null);
        navigate(routes.LOGIN);
      },
    },
  ];

  return (
    <>
      <div className="h-[70px] px-0 flex items-center justify-between text-[#525666] shadow-md bg-white">
        <div className="w-[190px]"></div>
        <div className="md:pr-5 flex items-center gap-8 pr-6">
          <Dropdown
            className="cursor-pointer"
            onOpenChange={setIsHovered}
            menu={{
              items,
            }}
            placement="bottomLeft"
          >
            <div className="flex items-center gap-2">
              <Avatar
                size={40}
                icon={<UserOutlined />}
                src={userInfor?.avataUrl}
                style={{
                  background: '#FF8833'
                }}
              />

              <div className="flex flex-col mr-10">
                <div className="flex items-center">
                  <span className="pr-1 text-[16px] font-semibold max-w-[80px] truncate">
                    {userName}
                  </span>
                  <Space>
                    {isHovered ? (
                      <UpOutlined style={{ fontSize: "10px" }} />
                    ) : (
                      <DownOutlined style={{ fontSize: "10px" }} />
                    )}
                  </Space>
                </div>
                <div className="text-[12px] font-light max-w-[80px] truncate">
                  {fullName}
                </div>
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
      <ChangePassword visible={visible} handleCancel={onClose} />
    </>
  );
};

export default Header;
