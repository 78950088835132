import React from "react";
import { PlusOutlined } from "@ant-design/icons";

function AddButton({ text, onClick }) {
  return (
    <div onClick={onClick}>
      <div className="bg-primary hover:bg-gradient-secondary px-3 py-[8px] text-white md:rounded-lg rounded cursor-pointer text-center text-sm inline-flex items-center gap-1">
        <div className="relative">
          <span className="animate-ping absolute text-[15px] -top-[1.2px] -left-[.9px]">
            <PlusOutlined />
          </span>
          <span>
            <PlusOutlined />
          </span>
        </div>
        <span className="whitespace-nowrap">{text}</span>
      </div>
    </div>
  );
}

export default AddButton;
