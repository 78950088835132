import { useNavigate, useParams } from "react-router-dom";
import { deleteNews, getNewsDetail } from "@/services/base";
import React, { useEffect, useState } from "react";
import { Divider, Flex, Typography, Image, Button, Row, Col, Tag, Modal, message, Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { routes } from "@/utils/const";

const NewsDetail = () => {
  document.title = "Tin tức chi tiết";
  const { id } = useParams();
  const [newsDetail, setNewsDetail] = useState();
  const [loading, setLoading] = useState(false);

  const nav = useNavigate();

  useEffect(() => {
    fetchNewsData();
  }, []);

  const fetchNewsData = async () => {
    try {
      const res = await getNewsDetail(id);
      if (!res.isError) {
        setNewsDetail(res.data);
      }
    } catch (error) {
      console.error('Error fetching topic:', error);
    }
  };

  const handleDelete = async (id) => {
    Modal.confirm({
      title: 'Xác nhận xóa',
      content: "Bạn có chắc chắn muốn xóa tin tức này?",
      okText: 'Xóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk: async () => {
        try {
          setLoading(true);
          const res = await deleteNews(id);
          if (!res.isError) {
            message.success(res.message);
            nav(routes.NEWS)
          } else {
            message.error(res.message);
          }
        } catch (error) {
          console.error('Error deleting news:', error);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  return (<>
      {loading ? (<div className="w-full min-h-[360px] flex items-center justify-center">
          <Spin size="large"/>
        </div>) : (<div className="news-detail">
          <Flex justify="space-between">
            <Flex align="center">
              <div className="flex items-center cursor-pointer mr-[10px] hover:opacity-80" onClick={() => {
                nav(-1)
              }}>
                <ArrowLeftOutlined/>
              </div>
              <Typography.Title level={3}
                                className="pl-4 mr-4 !mb-0 font-semibold text-center">{newsDetail?.title}</Typography.Title>
              {newsDetail?.isBlock ? <Tag className="text-lg">Riêng tư</Tag> :
                <Tag className="text-lg" color="green">Công khai</Tag>}
            </Flex>
            <div className="flex items-center space-x-2">
              <Button
                style={{
                  backgroundColor: "#FF9900",
                }}
                type="primary"
                onClick={() => nav(`/news/edit/${id}`)}
                className="shadow flex items-center rounded-md border px-2 py-1 space-x-1"
              >
                <span>Chỉnh sửa</span>
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => handleDelete(id)}
                className="shadow flex items-center border border-red-500 rounded-md text-red-500 px-2 py-1 space-x-1"
              >
                <span>Xóa tin tức</span>
              </Button>
            </div>
          </Flex>
          <Divider/>
          <section>
            <Row>
              <Typography.Title level={3} style={{ color: '#5d5d5d' }}>
                Thông tin tin tức
              </Typography.Title>
            </Row>
            <Row className="mt-4">
              <Col span={5}>
                <Typography.Text className="font-bold text-gray-700">Người tạo: </Typography.Text>
                <Typography.Text>{newsDetail?.createdUser.userName}</Typography.Text>
              </Col>
              <Col span={5}>
                <Typography.Text className="font-bold text-gray-700">Ngày tạo: </Typography.Text>
                <Typography.Text>{moment(newsDetail?.createDate).format("DD/MM/YYYY HH:mm")}</Typography.Text>
              </Col>
              <Col span={5}>
                <Typography.Text className="font-bold text-gray-700">Chủ đề: </Typography.Text>
                {newsDetail?.topics?.map(topic => <Tag>{topic.topicName}</Tag>)}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col span={5}>
                <Typography.Text className="font-bold text-gray-700">Ngày phát hành: </Typography.Text>
                <Typography.Text>
                  {newsDetail?.publishDate ? moment(newsDetail?.publishDate + "z").format("DD/MM/YYYY HH:mm") : 'Chưa có'}
                </Typography.Text>
              </Col>
              <Col span={5}>
                <Typography.Text className="font-bold text-gray-700">Nhãn: </Typography.Text>
                <Typography.Text>{newsDetail?.enumFlag ? newsDetail?.enumFlag.flagName : 'Chưa có'}</Typography.Text>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col span={5}>
                <Typography.Text className="font-bold text-gray-700">Người chỉnh sửa cuối: </Typography.Text>
                <Typography.Text>
                  {newsDetail?.modifiedUser.userName ? newsDetail?.modifiedUser.userName : 'Chưa có'}
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="font-bold text-gray-700">Lần cập nhật gần nhất: </Typography.Text>
                <Typography.Text>
                  {newsDetail?.modifiedDate ? moment(newsDetail?.modifiedDate).format("DD/MM/YYYY HH:mm") : 'Chưa có'}
                </Typography.Text>
              </Col>
            </Row>
          </section>
          <Divider/>
          <section>
            <Row>
              <Typography.Title level={3} style={{ color: '#5d5d5d' }}>
                Nội dung
              </Typography.Title>
            </Row>
            <Row className="mt-4">
              <Col>
                <Typography.Text className="font-bold text-gray-700">Mô tả: </Typography.Text>
                <Typography.Text>{newsDetail?.introduce}</Typography.Text>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col span={12}>
                <Typography.Text className="block font-bold mb-3 text-gray-700">Hình ảnh: </Typography.Text>
                <img src={`${process.env.REACT_APP_FILE}/${newsDetail?.imagePath}`}
                     alt={newsDetail?.title} className="max-w-full"/>
              </Col>
            </Row>
          </section>
          <Divider/>
          <Row className="mt-4">
            <Col>
              <Typography.Text className="block font-bold mb-4 text-gray-700">Nội dung tin tức: </Typography.Text>
              <CKEditor
                disabled
                config={{ toolbar: [] }}
                editor={Editor}
                data={newsDetail?.newsContent}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Button
              style={{
                backgroundColor: "#FF9900",
              }}
              type="primary"
              onClick={() => nav(-1)}
              className="shadow flex items-center rounded-md border px-2 py-1 space-x-1"
            >
              <ArrowLeftOutlined className="mr-1"/>
              Trở về
            </Button>
          </Row>
        </div>)}
    </>);
};
export default NewsDetail;
