import {
  getIntroduceDetail,
  getListTypes,
  updateIntroduce,
} from "@/services/base";
import { pageCMS, routes } from "@/utils/const";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import uploadPlugin from "@/utils/editor/uploadPlugin";

function UpdateIntroduce() {
  document.title = "Cập nhật giới thiệu";
  const [loading, setLoading] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [draft, setDraft] = useState("");
  const nav = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();

  const fetchTypeList = async () => {
    try {
      const page = pageCMS.find((item) => item.pageName === "Giới thiệu");
      if (page) {
        const res = await getListTypes({ cMSPageId: page.id });
        setTypeList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchIntroduce = async () => {
    if (!id) return;
    try {
      const res = await getIntroduceDetail(id);
      if (!res.isError) {
        const introduce = res.data;
        let values = {
          id: introduce.id,
          title: introduce.title,
          typeId: introduce.typeId,
          description: introduce.description,
          titleIntroduce: introduce.titleIntroduce,
          subTitleIntroduce: introduce.subTitleIntroduce,
          contentIntroduce: introduce.contentIntroduce,
        };

        setDraft(introduce?.contentDetail);

        form.setFieldsValue(values);
      }
    } catch (error) {
      console.error("Error fetching introduce:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchIntroduce();
      await fetchTypeList();
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      data.contentDetail = draft;
      const res = await updateIntroduce(data);

      if (!res.isError) {
        message.success(res.message);
        await fetchIntroduce();
        nav(routes.INTRODUCE);
      } else {
        message.error(res.message);
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error("Error update introduce:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-h-[360px] flex items-center justify-center ">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Row>
            <Flex
              className="cursor-pointer mr-4 hover:opacity-80"
              align="center"
              onClick={() => {
                nav(-1);
              }}
            >
              <ArrowLeftOutlined />
            </Flex>
            <p className="text-xl font-semibold">Cập nhật giới thiệu</p>
          </Row>
          <div className="py-6">
            <Form form={form} onFinish={onSubmit} layout="vertical">
              <Form.Item name="id" hidden>
                <Input hidden />
              </Form.Item>
              <Form.Item
                name="title"
                label={<p className="font-semibold ">Tiêu đề</p>}
                rules={[
                  {
                    required: true,
                    message: "Tiêu đề không được bỏ trống",
                  },
                ]}
              >
                <Input
                  size="large"
                  className="rounded-none"
                  placeholder="Nhập tiêu đề..."
                />
              </Form.Item>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label={<p className="font-semibold">Mô tả cho tiêu đề</p>}
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập mô tả cho tiêu đề!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      className="rounded-none"
                      size="large"
                      rows={5}
                      placeholder="Nhập mô tả cho tiêu đề..."
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    className="w-[300px]"
                    name="typeId"
                    label={<p className="font-semibold">Thể loại</p>}
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn thể loại",
                      },
                    ]}
                  >
                    <Select placeholder="Lựa chọn thể loại">
                      {typeList.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                          {item.typeName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="titleIntroduce"
                label={<p className="font-semibold">Tiêu đề cho nội dung</p>}
                rules={[
                  {
                    required: true,
                    message: "Tiêu đề cho nội dung không được bỏ trống",
                  },
                  {
                    max: 500,
                    message: "Tiêu đề không được quá 500 ký tự",
                  },
                ]}
              >
                <Input
                  size="large"
                  className="rounded-none"
                  placeholder="Nhập tiêu đề cho nội dung..."
                />
              </Form.Item>
              <Form.Item
                name="subTitleIntroduce"
                label={
                  <p className="font-semibold">Tiêu đề phụ cho nội dung</p>
                }
                rules={[
                  {
                    max: 500,
                    message: "Tiêu đề phụ không được quá 500 ký tự",
                  },
                ]}
              >
                <Input
                  size="large"
                  className="rounded-none"
                  placeholder="Nhập tiêu đề phụ cho nội dung..."
                />
              </Form.Item>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="contentIntroduce"
                    label={<p className="font-semibold">Nội dung</p>}
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập nội dung!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      className="rounded-none"
                      size="large"
                      rows={5}
                      placeholder="Nhập nội dung..."
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="contentDetail"
                    label={<p className="font-semibold">Nội dung xem thêm</p>}
                  >
                    <CKEditor
                      config={{ extraPlugins: [uploadPlugin] }}
                      editor={Editor}
                      onReady={(editor) => {}}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                      onChange={(event, editor) => {
                        setDraft(editor.getData());
                      }}
                      data={draft}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Button
                style={{ backgroundColor: "#FF9900" }}
                type="primary"
                htmlType="submit"
                className="mt-2"
              >
                Cập nhật giới thiệu
              </Button>
              <Button
                type="primary"
                danger
                className="ml-2"
                onClick={() => nav(routes.INTRODUCE)}
              >
                Hủy bỏ
              </Button>
            </Form>
          </div>
        </div>
      )}
    </>
  );
}

export default UpdateIntroduce;
