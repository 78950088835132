import React, { useCallback, useEffect, useState } from "react";
import { Button, Divider, message, Modal, Space, Tooltip, Image } from "antd";
import DataTable from "@/components/common/DataTable";
import moment from "moment";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import TotalCount from "@/components/common/TotalCount";
import {
  createTopic,
  deleteTopic,
  getListTopics,
  updateTopic,
  getListTypes,
} from "@/services/base";
import TopicFilterForm from "@/components/topics/TopicFilterForm";
import TopicActionForm from "@/components/topics/TopicActionForm";
import { pageCMS } from "@/utils/const";

const Topics = () => {
  document.title = "Quản lý chủ đề";
  const [loading, setLoading] = useState(false);
  const [listTopic, setListTopic] = useState([]);
  const [typePage, setTypePage] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [params, setParams] = useState({
    textSearch: "",
    type: "",
    pageIndex: 1,
    pageSize: 10,
    total: 0,
  });

  const [modalState, setModalState] = useState({
    isOpen: false,
    isCreate: true,
    title: "Tạo chủ đề",
    buttonOk: "Tạo mới",
    editData: null,
  });

  const fetchTopics = async (searchParams) => {
    try {
      setLoading(true);
      const res = await getListTopics(searchParams);
      if (!res.isError) {
        setListTopic(res.data);
        setTotalPage(res?.pagination?.total);
      }
    } catch (error) {
      console.error("Error fetching topic:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTypes = async () => {
    try {
      const res = await getListTypes({
        cMSPageId: pageCMS[0].id,
        pageSize: 100,
      });
      if (!res.isError) {
        setTypePage(res.data);
      }
    } catch (error) {
      console.error("Error fetching type:", error);
    }
  };

  useEffect(() => {
    fetchTypes();
  }, []);

  useEffect(() => {
    fetchTopics(params);
  }, [params]);

  const onSearch = (value) => {
    setParams((prev) => ({
      ...prev,
      ...value,
      pageIndex: 1,
    }));
  };

  const onchangePage = (page, pageSize) => {
    setParams((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize,
    }));
  };

  const handleEdit = (record) => {
    setModalState({
      isOpen: true,
      isCreate: false,
      buttonOk: "Cập nhật",
      editData: record,
      title: "Chỉnh sửa chủ đề",
    });
  };

  const handleDelete = async (record) => {
    Modal.confirm({
      title: "Xác nhận xóa",
      content: `Bạn có chắc chắn muốn xóa chủ đề "${record.topicName}"?`,
      okText: "Xóa",
      okType: "danger",
      cancelText: "Hủy",
      onOk: async () => {
        try {
          setLoading(true);
          const res = await deleteTopic(record.id);
          if (!res.isError) {
            message.success(res.message);
            await fetchTopics({
              pageIndex: 1,
              pageSize: 10,
            });
          } else {
            message.error(res.message);
          }
        } catch (error) {
          console.error("Error deleting topic:", error);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const actionTopic = async (topicData) => {
    try {
      setLoading(true);
      let res;
      if (modalState.isCreate) {
        res = await createTopic(topicData);
      } else {
        res = await updateTopic({
          ...topicData,
          isActive: true,
        });
      }
      if (!res.isError) {
        message.success(res.message);
        setModalState({ ...modalState, isOpen: false });
        await fetchTopics({
          pageIndex: 1,
          pageSize: 10,
        });
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.error("Error saving topic:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Biểu tượng",
      dataIndex: "iconPath",
      width: 100,
      render: (value) => {
        return value ? (
          <Image width={100} src={process.env.REACT_APP_FILE + "/" + value} />
        ) : (
          "--"
        );
      },
    },
    {
      title: "Hình nền",
      dataIndex: "backgroundPath",
      width: 100,
      render: (value) => {
        return value ? (
          <Image width={100} src={process.env.REACT_APP_FILE + "/" + value} />
        ) : (
          "--"
        );
      },
    },
    {
      title: "Tên chủ đề",
      dataIndex: "topicName",
    },
    {
      title: "Thể loại",
      dataIndex: "type",
      render: (item) => item?.typeName,
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      render: (item) => (item ? moment(item + "z").format("DD/MM/YYYY") : "--"),
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) => {
        return (
          <Space>
            <Tooltip title={"Chỉnh sửa"}>
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => handleEdit(record)}
              />
            </Tooltip>
            <Tooltip title={"Xóa"}>
              <Button
                type="primary"
                shape="circle"
                danger
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => handleDelete(record)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <TopicFilterForm
        typePage={typePage}
        onSearch={onSearch}
        createTopic={() =>
          setModalState({
            isOpen: true,
            isCreate: true,
            buttonOk: "Tạo mới",
            editData: null,
            title: "Tạo chủ đề",
          })
        }
      />
      <TotalCount text="chủ đề" loading={loading} total={totalPage} />
      <Divider />
      <DataTable
        columns={columns}
        dataSource={listTopic.map((u) => ({ ...u, key: u.id })) || []}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={params.total}
        loading={loading}
        onPageChange={onchangePage}
      />
      <TopicActionForm
        typePage={typePage}
        modalState={modalState}
        closeModal={() => setModalState({ ...modalState, isOpen: false })}
        actionTopic={actionTopic}
      />
    </div>
  );
};
export default Topics;
