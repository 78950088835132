import { Flex, Tag, Typography } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

function TotalCount({text, loading, total}) {
  return (
    <Flex className="mt-4" align="center">
      <Typography.Text>
        {`Tổng số ${text}: `}
        <Tag
          color="#FF9900"
          className="inline-flex items-center justify-between gap-1"
        >
          {loading ? (
            <LoadingOutlined
              style={{
                fontSize: 10,
              }}
            />
          ) : (
            total || 0
          )}{" "}
          {text}
        </Tag>
      </Typography.Text>
    </Flex>
  );
}

export default TotalCount;