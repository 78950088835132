import { infoUser } from "@/recoil/atoms";
import { getUserById, postFile, updateProfile } from "@/services/base";
import { StatusResponse } from "@/utils/const";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  Upload,
  message,
  Select,
} from "antd";
import React, { useState } from "react";
import { useRecoilState } from "recoil";

const UserProfile = () => {
  const [form] = Form.useForm();
  const [dataInfoUser, setDataInfoUser] = useRecoilState(infoUser);
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrl, setImageUrl] = useState(dataInfoUser?.avataUrl);
  const [datePicker, setDatePicker] = useState(dataInfoUser?.dateOfBirth);

  const getImageUrl = (filePath) => {
    if (filePath) {
      let baseUrl = process.env.REACT_APP_API_URL;
      if (baseUrl.endsWith("/api")) {
        baseUrl = baseUrl.slice(0, -4);
      }

      return `${baseUrl}/${filePath}`;
    }
    return null;
  };

  const uploadFile = async () => {
    try {
      const response = await postFile(imageUpload);
      return response.data?.filePath;
    } catch (error) {
      console.error("Error storing file:", error);
      throw error;
    }
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleChange = (info) => {
    if (info.file.originFileObj) {
      if (info.file.originFileObj instanceof File) {
        getBase64(info.file.originFileObj, (imageUrl) => {
          setImageUrl(imageUrl);
          setImageUpload(info.file.originFileObj);
        });
      } else {
        console.error("originFileObj is not a File:", info.file.originFileObj);
      }
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Bạn chỉ có thể tải lên ảnh!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const onFinish = async (values) => {
    const avatarUrl = imageUpload
      ? getImageUrl(await uploadFile())
      : dataInfoUser?.avataUrl;

    try {
      const dataUpdate = {
        ...dataInfoUser,
        ...values,
        avataUrl: avatarUrl,
        phoneNumber: values.phoneNumber,
        dateOfBirth: null,
      };

      const response = await updateProfile(dataUpdate);

      if (response.code !== StatusResponse.SUCCESS) {
        message.error(response.message);
      } else {
        message.success(response.message);
        const getMe = await getUserById(response.data.id);
        if (getMe.code === StatusResponse.SUCCESS) setDataInfoUser(getMe.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = (date) => {
    const datePicker = new Date(date);
    datePicker.setHours(datePicker.getHours() + 7);
    setDatePicker(datePicker.toISOString());
  };

  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      wrapperCol={{
        span: 18,
      }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={
        dataInfoUser
          ? {
              userName: dataInfoUser.userName,
              fullName: dataInfoUser.fullName,
              userEmail: dataInfoUser.userEmail,
              phoneNumber: dataInfoUser.phoneNumber,
              departmentName: dataInfoUser.departmentName,
              isActive: dataInfoUser.isActive ? "Hoạt động" : "Không hoạt động",
              // dateOfBirth: datePicker,
            }
          : {}
      }
    >
      {/* <Row>
        <Col span={8}>
          <Form.Item name="avatar" label="Ảnh đại diện" className="font-bold">
            <Space align="start">
              <Upload
                name="avatar"
                listType="picture-circle"
                className="avatar-uploader"
                showUploadList={false}
                onChange={handleChange}
                beforeUpload={beforeUpload}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                      width: 200,
                      height2: 200,
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <button
                    style={{
                      border: 0,
                      background: "none",
                      width: 200,
                      height2: 200,
                    }}
                    type="button"
                  >
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Tải lên
                    </div>
                  </button>
                )}
              </Upload>
            </Space>
          </Form.Item>
        </Col>
      </Row> */}
      <Row>
        <Col span={8}>
          <Form.Item
            label="Tên đăng nhập"
            name="userName"
            className="font-bold"
          >
            <Input size="large" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Tên nhân viên"
            name="fullName"
            className="font-bold"
          >
            <Input size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        {/* <Col span={8}>
          <Form.Item label="Ngày sinh" name="dateOfBirth" className="font-bold">
            <DatePicker
              format="DD/MM/YYYY"
              allowClear={false}
              className="w-full"
              size="large"
              onChange={handleDateChange}
              disabledDate={(current) => {
                return current && current.valueOf() > Date.now();
              }}
            />
          </Form.Item>
        </Col> */}
        <Col span={8}>
          <Form.Item
            label="Số điện thoại"
            name="phoneNumber"
            className="font-bold"
            rules={[
              {
                pattern: /^(\+\d{1,14}|\d{1,14})$/,
                message: "Vui lòng nhập đúng định dạng số điện thoại!",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Email"
            name="userEmail"
            className="font-bold"
            rules={[
              {
                type: "email",
                message: "Vui lòng nhập đúng định dạng email!",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        {/* <Col span={8}>
          <Form.Item
            label="Bộ phận"
            name="departmentName"
            className="font-bold"
          >
            <Select size="large" options></Select>
          </Form.Item>
        </Col> */}

        <Col span={8}>
          <Form.Item label="Trạng thái" name="isActive" className="font-bold">
            <Input size="large" disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item>
            <Button
              style={{
                backgroundColor: "#ffa827",
                width: "100%",
                height: "40px",
                color: "#fff",
                marginTop: "20px",
              }}
              className="custom-button"
              size="large"
              htmlType="submit"
            >
              Chỉnh sửa
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default UserProfile;
