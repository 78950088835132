import * as api from "@/configs/url_api";
import { APIBase } from "@/configs/axiosBase";

const apiBase = new APIBase();

// Auth
export const authSignIn = (data) => apiBase.post(api.AUTH_LOGIN, data);
export const authRefreshToken = (data) =>
  apiBase.post(api.AUTH_REFRESH_TOKEN, data);
export const authChangePass = (data) =>
  apiBase.post(api.AUTH_CHANGE_PASS, data);

// Users
export const getListUser = ({ textSearch, pageIndex, pageSize }) => {
  const queryParams = new URLSearchParams();
  if (textSearch) queryParams.set("textSearch", textSearch);
  if (pageIndex) queryParams.set("pageIndex", pageIndex);
  if (pageSize) queryParams.set("pageSize", pageSize);

  const queryString = queryParams.toString();
  const endpoint = `${api.USER_GET_LIST}${
    queryString ? `?${queryString}` : ""
  }`;
  return apiBase.get(endpoint);
};
export const getUserById = (id) => apiBase.get(`${api.USER}/${id}`);
export const createUser = (data) => apiBase.post(api.USER_CREATE, data);
export const updateUser = (data) => apiBase.put(api.USER_UPDATE, data);
export const deleteUser = (id) => apiBase.delete(`${api.USER}/${id}`);
export const updateProfile = (data) =>
  apiBase.put(api.USER_UPDATE_PROFILE, data);

// Pages
export const getListPages = () => apiBase.get(api.PAGE_GET_LIST);

// Page Name CMS
export const getPageNameCms = () =>
  apiBase.get(`${api.PAGE_NAME_CMS_GET_LIST}`);

// Types
export const getListTypes = ({
  textSearch,
  pageIndex,
  pageSize,
  cMSPageId,
}) => {
  const queryParams = new URLSearchParams();
  if (textSearch) queryParams.set("textSearch", textSearch);
  if (pageIndex) queryParams.set("pageIndex", pageIndex);
  if (pageSize) queryParams.set("pageSize", pageSize);
  if (cMSPageId) queryParams.set("cMSPageId", cMSPageId);

  const queryString = queryParams.toString();
  const endpoint = `${api.TYPE_GET_LIST}${
    queryString ? `?${queryString}` : ""
  }`;
  return apiBase.get(endpoint);
};
export const createType = (data) => apiBase.post(api.TYPE_CREATE, data);
export const updateType = (data) => apiBase.put(api.TYPE_UPDATE, data);
export const deleteType = (id) => apiBase.delete(`${api.TYPE}/${id}`);

// Topics
export const getListTopics = ({ textSearch, typeId, pageIndex, pageSize }) => {
  const queryParams = new URLSearchParams();
  if (textSearch) queryParams.set("textSearch", textSearch);
  if (typeId) queryParams.set("typeId", typeId);
  if (pageIndex) queryParams.set("pageIndex", pageIndex);
  if (pageSize) queryParams.set("pageSize", pageSize);

  const queryString = queryParams.toString();
  const endpoint = `${api.TOPIC_GET_LIST}${
    queryString ? `?${queryString}` : ""
  }`;
  return apiBase.get(endpoint);
};
export const createTopic = (data) => apiBase.post(api.TOPIC_CREATE, data);
export const updateTopic = (data) => apiBase.put(api.TOPIC_UPDATE, data);
export const deleteTopic = (id) => apiBase.delete(`${api.TOPIC}/${id}`);

// News
export const getListNews = ({
  textSearch,
  listTopicId,
  flagId,
  isBlock,
  pageIndex,
  pageSize,
}) => {
  const queryParams = new URLSearchParams();
  if (isBlock == 0 || isBlock == 1) queryParams.set("isBlock", isBlock);
  if (flagId) queryParams.set("flagId", flagId);
  if (textSearch) queryParams.set("textSearch", textSearch);
  if (listTopicId && Array.isArray(listTopicId)) {
    listTopicId.forEach((id) => queryParams.append("listTopicId", id));
  }
  if (pageIndex) queryParams.set("pageIndex", pageIndex);
  if (pageSize) queryParams.set("pageSize", pageSize);

  const queryString = queryParams.toString();
  const endpoint = `${api.NEWS_GET_LIST}${
    queryString ? `?${queryString}` : ""
  }`;
  return apiBase.get(endpoint);
};
export const getNewsDetail = (id) => apiBase.get(`${api.NEWS}/${id}`);
export const createNews = (data) => apiBase.post(api.NEWS_CREATE, data);
export const updateNews = (data) => apiBase.put(api.NEWS_UPDATE, data);
export const deleteNews = (id) =>
  apiBase.delete(`${api.NEWS_HARD_DELETE}/${id}`);

// Videos
export const getListVideos = (data) => {
  const endpoint = `${api.VIDEO_GET_LIST}${data ? `?${data}` : ""}`;
  return apiBase.get(endpoint);
};
export const createVideo = (data) => apiBase.post(api.VIDEO_CREATE, data);
export const updateVideo = (data) => apiBase.put(api.VIDEO_UPDATE, data);
export const deleteVideo = (id) => apiBase.delete(`${api.VIDEO}/${id}`);
export const deleteSoftVideo = (id) =>
  apiBase.delete(`${api.VIDEO_SOFT_DELETE}/${id}`);
export const deleteHardVideo = (id) =>
  apiBase.delete(`${api.VIDEO_HARD_DELETE}/${id}`);
export const getDetailVideo = (id) => apiBase.delete(`${api.VIDEO}/${id}`);

// Images
export const getlistImage = ({ textSearch, typeId, pageIndex, pageSize }) => {
  const queryParams = new URLSearchParams();
  if (textSearch) queryParams.set("textSearch", textSearch);
  if (pageIndex) queryParams.set("pageIndex", pageIndex);
  if (pageSize) queryParams.set("pageSize", pageSize);
  if (typeId) queryParams.set("typeId", typeId);

  const queryString = queryParams.toString();
  const endpoint = `${api.IMAGE_GET_LIST}${
    queryString ? `?${queryString}` : ""
  }`;
  return apiBase.get(endpoint);
};
export const createImage = (data) => apiBase.post(api.IMAGE_CREATE, data);
export const updateImage = (data) => apiBase.put(api.IMAGE_UPDATE, data);
export const deleteSoftImage = (id) =>
  apiBase.delete(`${api.IMAGE_SOFT_DELETE}/${id}`);
export const deleteHardImage = (id) =>
  apiBase.delete(`${api.IMAGE_HARD_DELETE}/${id}`);

//Links
export const getListLinks = ({ textSearch, typeId, pageIndex, pageSize }) => {
  const queryParams = new URLSearchParams();
  if (textSearch) queryParams.set("textSearch", textSearch);
  if (typeId) queryParams.set("typeId", typeId);
  if (pageIndex) queryParams.set("pageIndex", pageIndex);
  if (pageSize) queryParams.set("pageSize", pageSize);

  const queryString = queryParams.toString();
  const endpoint = `${api.LINKS_GET_LIST}${
    queryString ? `?${queryString}` : ""
  }`;
  return apiBase.get(endpoint);
};
export const createLink = (data) => apiBase.post(api.LINKS_CREATE, data);
export const updateLink = (data) => apiBase.put(api.LINKS_UPDATE, data);
export const deleteLink = (id) =>
  apiBase.delete(`${api.LINKS_HARD_DELETE}/${id}`);

// Guides
export const getListInstructs = ({ titleId, textSearch, pageIndex, pageSize }) => {
  const queryParams = new URLSearchParams();
  if (textSearch) queryParams.set("textSearch", textSearch);
  if (pageIndex) queryParams.set("pageIndex", pageIndex);
  if (pageSize) queryParams.set("pageSize", pageSize);
  if (titleId) queryParams.set("titleId", titleId);

  const queryString = queryParams.toString();
  const endpoint = `${api.INSTRUCT_GET_LIST}${
    queryString ? `?${queryString}` : ""
  }`;
  return apiBase.get(endpoint);
};
export const createInstruct = (data) => apiBase.post(api.INSTRUCT_CREATE, data);
export const updateInstruct = (data) => apiBase.put(api.INSTRUCT_UPDATE, data);
export const deleteInstruct = (id) => apiBase.delete(`${api.INSTRUCT}/${id}`);
export const getDetailInstruct = (id) => apiBase.get(`${api.INSTRUCT}/${id}`);

// Upload File
export const postFile = (data) =>
  apiBase.post(
    api.UPLOAD_FILE,
    { file: data },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

// Introduce
export const getListIntroduce = (data) =>
  apiBase.get(api.INTRODUCE, { params: data });

export const addIntroduce = (data) => apiBase.post(api.CREATE_INTRODUCE, data);

export const getIntroduceDetail = (id) =>
  apiBase.get(`${api.DETAIL_INTRODUCE}/${id}`);

export const updateIntroduce = (data) =>
  apiBase.put(api.UPDATE_INTRODUCE, data);

export const deleteIntroduce = (id) =>
  apiBase.delete(`${api.DELETE_INTRODUCE}/${id}`);

// Sub Introduce
export const getListSubIntroduce = (data) =>
  apiBase.get(api.SUB_INTRODUCE, { params: data });

export const updateSubIntroduce = (data) =>
  apiBase.put(api.SUB_UPDATE_INTRODUCE, data);
export const createSubIntroduce = (data) =>
  apiBase.post(api.SUB_CREATE_INTRODUCE, data);
export const deleteSubIntroduce = (id) =>
  apiBase.delete(`${api.SUB_DELETE_INTRODUCE}/${id}`);

export const getSubIntroduceDetail = (id) =>
  apiBase.get(`${api.SUB_DETAIL_INTRODUCE}/${id}`);

// TitleInstruct
export const getListTitleInstruct = (data) =>
  apiBase.get(api.TITLE_INSTRUCT_GET_LIST, { params: data });
export const createTitleInstruct = (data) =>
  apiBase.post(api.TITLE_INSTRUCT_CREATE, data);
export const updateTitleInstruct = (id) =>
  apiBase.put(api.TITLE_INSTRUCT_UPDATE, id);
export const deleteTitleInstruct = (id) =>
  apiBase.delete(`${api.TITLE_INSTRUCT}/${id}`);
export const getDetailTitleInstruct = (id) =>
  apiBase.get(`${api.TITLE_INSTRUCT}/${id}`);

// link main
export const getLinkMain = () => apiBase.get(api.LINK_MAIN)
export const updateLinkMain = (body) => apiBase.put(api.LINK_MAIN, body)
