import { deleteIntroduce, getIntroduceDetail } from "@/services/base";
import { routes } from "@/utils/const";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  Col,
  Divider,
  Flex,
  Modal,
  Row,
  Spin,
  Tag,
  Typography,
  message,
} from "antd";
import Editor from "ckeditor5-custom-build";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

function DetailIntroduce() {
  document.title = "Giới thiệu chi tiết";
  const { id } = useParams();
  const [introduceDetail, setIntroduceDetail] = useState();
  const [loading, setLoading] = useState(false);

  const nav = useNavigate();

  useEffect(() => {
    fetchIntroduceData();
  }, []);

  const fetchIntroduceData = async () => {
    try {
      const res = await getIntroduceDetail(id);
      if (!res.isError) {
        setIntroduceDetail(res.data);
      }
    } catch (error) {
      console.error("Error fetching topic:", error);
    }
  };

  const handleDelete = async (id) => {
    Modal.confirm({
      title: "Xác nhận xóa",
      content: "Bạn có chắc chắn muốn xóa mục giới thiệu này?",
      okText: "Xóa",
      okType: "danger",
      cancelText: "Hủy",
      onOk: async () => {
        try {
          setLoading(true);
          const res = await deleteIntroduce(id);
          if (!res.isError) {
            message.success(res.message);
            nav(routes.INTRODUCE);
          } else {
            message.error(res.message);
          }
        } catch (error) {
          console.error("Error deleting introduce:", error);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-h-[360px] flex items-center justify-center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="news-detail">
          <Flex justify="space-between">
            <Flex align="center">
              <div
                className="flex items-center cursor-pointer mr-[10px] hover:opacity-80"
                onClick={() => {
                  nav(-1);
                }}
              >
                <ArrowLeftOutlined />
              </div>
              <Typography.Title
                level={3}
                className="pl-4 mr-4 !mb-0 font-semibold text-center"
              >
                {introduceDetail?.title}
              </Typography.Title>
            </Flex>
            <div className="flex items-center space-x-2">
              <Button
                style={{
                  backgroundColor: "#FF9900",
                }}
                type="primary"
                onClick={() => nav(`/introduce/update/${id}`)}
                className="shadow flex items-center rounded-md border px-2 py-1 space-x-1"
              >
                <span>Chỉnh sửa</span>
              </Button>
              {/* <Button
                type="primary"
                danger
                onClick={() => handleDelete(id)}
                className="shadow flex items-center border border-red-500 rounded-md text-red-500 px-2 py-1 space-x-1"
              >
                <span>Xóa giới thiệu</span>
              </Button> */}
            </div>
          </Flex>
          <Divider />
          <section>
            <Row>
              <Typography.Title level={3} style={{ color: "#5d5d5d" }}>
                Thông tin giới thiệu
              </Typography.Title>
            </Row>
            <Row className="mt-4">
              <Col span={5}>
                <Typography.Text className="font-bold text-gray-700">
                  Thời gian tạo:{" "}
                </Typography.Text>
                <Typography.Text>
                  {moment(introduceDetail?.createDate).format("DD/MM/YYYY")}
                </Typography.Text>
              </Col>
              <Col span={5}>
                <Typography.Text className="font-bold text-gray-700">
                  Thời gian chỉnh sửa:{" "}
                </Typography.Text>
                <Typography.Text>
                  {moment(introduceDetail?.modifiedDate).format("DD/MM/YYYY")}
                </Typography.Text>
              </Col>
            </Row>
          </section>
          <Divider />
          <section>
            <Row>
              <Typography.Title level={3} style={{ color: "#5d5d5d" }}>
                Mô tả cho tiêu đề
              </Typography.Title>
            </Row>
            <Row className="mt-4">
              <Col span={24}>
                <Typography.Text>
                  {introduceDetail?.description}
                </Typography.Text>
              </Col>
            </Row>
          </section>
          <Divider />
          <section>
            <Row>
              <Typography.Title level={3} style={{ color: "#5d5d5d" }}>
                Thể loại
              </Typography.Title>
            </Row>
            <Row className="mt-4">
              <Col span={24}>
                <Typography.Text>
                  {introduceDetail?.type?.typeName}
                </Typography.Text>
              </Col>
            </Row>
          </section>
          <Divider />

          <section>
            <Row>
              <Typography.Title level={3} style={{ color: "#5d5d5d" }}>
                Tiêu đề cho nội dung
              </Typography.Title>
            </Row>
            <Row className="mt-4">
              <Col>
                <div>{introduceDetail?.titleIntroduce}</div>
              </Col>
            </Row>
          </section>
          <Divider />

          <section>
            <Row>
              <Typography.Title level={3} style={{ color: "#5d5d5d" }}>
                Tiêu đề phụ cho nội dung
              </Typography.Title>
            </Row>
            <Row className="mt-4">
              <Col>
                <div>{introduceDetail?.subTitleIntroduce || "Không có"}</div>
              </Col>
            </Row>
          </section>
          <Divider />

          <section>
            <Row>
              <Typography.Title level={3} style={{ color: "#5d5d5d" }}>
                Nội dung
              </Typography.Title>
            </Row>
            <Row className="mt-4">
              <Col>
                <div
                  dangerouslySetInnerHTML={{
                    __html: introduceDetail?.contentIntroduce,
                  }}
                ></div>
              </Col>
            </Row>
          </section>
          <Divider />
          <Row className="mt-4">
            <Button
              style={{
                backgroundColor: "#FF9900",
              }}
              type="primary"
              onClick={() => nav(-1)}
              className="shadow flex items-center rounded-md border px-2 py-1 space-x-1"
            >
              <ArrowLeftOutlined className="mr-1" />
              Trở về
            </Button>
          </Row>
        </div>
      )}
    </>
  );
}

export default DetailIntroduce;
