import { Button, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getPageNameCms } from "@/services/base";

const TypeActionForm = ({ modalState, closeModal, actionType }) => {
  const { isOpen, title, buttonOk, editData } = modalState;
  const [pages, setPages] = useState([])
  const [form] = Form.useForm();

  const fetchPages = async () => {
    try {
      const res = await getPageNameCms();
      if (!res.isError) {
        setPages(res.data.data);
      }
    } catch (error) {
      console.error('Error fetching page:', error);
    }
  }

  useEffect(() => {
    fetchPages();
    if (editData) {
      form.setFieldsValue({
        ...editData,
      });
    } else {
      form.resetFields();
    }
  }, [isOpen, editData, form]);

  const onFinish = async (fieldValues) => {
    await actionType({
      ...fieldValues,
    });
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      width="600px"
      onCancel={() => {
        closeModal();
        form.resetFields();
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ isBlock: false }}
      >
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label="Tên thể loại"
          name="typeName"
          rules={[
            { required: true, message: "Vui lòng không để trống." },
          ]}
        >
          <Input placeholder="Nhập tên thể loại..." />
        </Form.Item>
        <Form.Item label="Thuộc trang" name="pageCmsid" rules={[
          { required: true, message: "Vui lòng không để trống." },
        ]}>
          <Select
            allowClear
            showSearch
            placeholder="Chọn trang"
            options={pages?.map((item) => {
              return {
                value: item.id,
                label: item.pageName,
              }
            })}
            filterOption={(input, option) =>
              (option?.children ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
            }
          ></Select>
        </Form.Item>
        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            className="w-[105px] h-[38px] inline-flex justify-center items-center px-[24px] py-[10px] text-[16px] font-semibold"
          >
            {buttonOk}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default TypeActionForm;
