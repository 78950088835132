import {
  getDetailTitleInstruct,
  updateInstruct,
  updateTitleInstruct,
} from "@/services/base";
import { routes } from "@/utils/const";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

function TitleInstructUpdate() {
  document.title = "Cập nhật tiêu đề hướng dẫn";
  const [loading, setLoading] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [draft, setDraft] = useState("");
  const nav = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();

  const fetchInstruct = async () => {
    if (!id) return;
    try {
      const res = await getDetailTitleInstruct(id);
      if (!res.isError) {
        const instruct = res.data;
        let values = {
          id: instruct.id,
          title: instruct.title,
        };

        form.setFieldsValue(values);
      }
    } catch (error) {
      console.error("Error fetching Instruct:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchInstruct();
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const res = await updateTitleInstruct(data);

      if (!res.isError) {
        message.success(res.message);
        await fetchInstruct();
        nav(routes.TITLE_INSTRUCT);
      } else {
        message.error(res.message);
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error("Error update Instruct:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-h-[360px] flex items-center justify-center ">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Row>
            <Flex
              className="cursor-pointer mr-4 hover:opacity-80"
              align="center"
              onClick={() => {
                nav(-1);
              }}
            >
              <ArrowLeftOutlined />
            </Flex>
            <p className="text-xl font-semibold">Cập nhật tiêu đề hướng dẫn</p>
          </Row>
          <div className="py-6">
            <Form form={form} onFinish={onSubmit} layout="vertical">
              <Form.Item name="id" hidden>
                <Input hidden />
              </Form.Item>
              <Form.Item
                name="title"
                label={<p className="font-semibold ">Tiêu đề</p>}
                rules={[
                  {
                    required: true,
                    message: "Tiêu đề không được bỏ trống",
                  },
                ]}
              >
                <Input
                  size="large"
                  className="rounded-none"
                  placeholder="Nhập tiêu đề..."
                />
              </Form.Item>
              <Button
                style={{ backgroundColor: "#FF9900" }}
                type="primary"
                htmlType="submit"
                className="mt-2"
              >
                Cập nhật tiêu đề
              </Button>
              <Button
                type="primary"
                danger
                className="ml-2"
                onClick={() => nav(routes.TITLE_INSTRUCT)}
              >
                Hủy bỏ
              </Button>
            </Form>
          </div>
        </div>
      )}
    </>
  );
}

export default TitleInstructUpdate;
