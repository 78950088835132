import { Button, Select, Form, Input, Modal, Row, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { createImage, updateImage, postFile } from "@/services/base";

const ImageActionForm = ({
  typePage,
  modalState,
  closeModal,
  reload,
  messageUpload,
  setMessageUpload,
}) => {
  const { isOpen, title, buttonOk, editData } = modalState;
  const [form] = Form.useForm();
  const [objectUrlImage, setObjectUrlImage] = useState(null);

  const [imageUrl, setImageUrl] = useState("");
  const [isChangeUpload, setIsChangeUpload] = useState(false);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
      });
      setObjectUrlImage(editData.imagePath);
      setImageUrl(process.env.REACT_APP_FILE + "/" + editData.imagePath);
    } else {
      form.resetFields();
      setImageUrl("");
    }
  }, [isOpen, editData, form]);

  const handleSubmitSuccess = () => {
    reload();
    form.resetFields();
    closeModal();
    setObjectUrlImage(null);
  };

  const handleCancelModal = () => {
    closeModal();
    form.resetFields();
    setObjectUrlImage(null);
  };

  const onFinish = async (fieldValues) => {
    if (!!editData) {
      let dataFilePath = editData.imagePath;
      if (isChangeUpload) {
        const { data } = await postFile(objectUrlImage);
        dataFilePath = data.filePath;
      }

      const res = await updateImage({
        ...fieldValues,
        imagePath: dataFilePath,
      });

      if (res.code === 200) {
        message.success("cập nhật hình ảnh thành công");
        handleSubmitSuccess();
      } else {
        message.error(res.message);
      }
    } else {
      const { data } = await postFile(objectUrlImage);

      const res = await createImage({
        ...fieldValues,
        imagePath: data.filePath,
      });

      if (res.code === 200) {
        message.success("Thêm hình ảnh thành công");
        handleSubmitSuccess();
      } else {
        message.error(res.message);
      }
    }
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      setMessageUpload("Chỉ được tải hình ảnh lên");
      return Upload.LIST_IGNORE;
    }

    const isLt5MB = file.size / 1024 / 1024 < 5;
    if (!isLt5MB) {
      setMessageUpload("Hình ảnh tải lên phải bé hơn 5MB");
      return Upload.LIST_IGNORE;
    }

    const objectUrlImage = URL.createObjectURL(file);
    setObjectUrlImage(file);
    setImageUrl(objectUrlImage);
    setIsChangeUpload(true);
    setMessageUpload("");

    return false;
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      width="600px"
      onCancel={() => handleCancelModal()}
      footer={null}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ isBlock: false }}
      >
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label="Tiêu đề"
          name="imageName"
          rules={[{ required: true, message: "Vui lòng không để trống." }]}
        >
          <Input placeholder="Nhập tiêu đề của hình ảnh..." />
        </Form.Item>
        <Form.Item
          label="Chọn thể loại"
          name={["typeId"]}
          rules={[{ required: true, message: "Vui lòng chọn thể loại" }]}
        >
          <Select
            allowClear
            showSearch
            placeholder="Chọn thể loại"
            filterOption={(input, option) =>
              (option?.children ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {typePage?.map((type) => (
              <Select.Option key={type.id} value={type.id}>
                {type.typeName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Vui lòng chọn chủ đề" }]}
        >
          <img src={imageUrl} alt="" className="max-w-[100px]" />

          <div className="mt-1">
            <Upload
              listType="picture"
              maxCount={1}
              showUploadList={false}
              beforeUpload={beforeUpload}
            >
              <Button icon={<UploadOutlined />}>Tải lên hình ảnh</Button>
            </Upload>
          </div>
          <div className="text-[#ff4d4f] text-sm mt-1">{messageUpload}</div>
        </Form.Item>
        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            className="w-[105px] h-[38px] inline-flex justify-center items-center px-[24px] py-[10px] text-[16px] font-semibold"
          >
            {buttonOk}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default ImageActionForm;
