import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  message,
  Image,
} from "antd";
import React, { useEffect, useState } from "react";
import { getListTypes, postFile } from "@/services/base";
import { PlusOutlined } from "@ant-design/icons";

const UserActionForm = ({ typePage, modalState, closeModal, actionTopic }) => {
  const { isOpen, title, buttonOk } = modalState;
  const [form] = Form.useForm();
  const [editData, setEditData] = useState();
  const [objectUrlBackround, setObjectUrlBackround] = useState();
  const [objectUrlIcon, setObjectUrlIcon] = useState();
  const [fileListIcon, setFileListIcon] = useState();
  const [fileListBackground, setfileListBackground] = useState();
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);

  useEffect(() => {
    setEditData(modalState?.editData);
  }, [modalState])

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
      });

      if (editData?.iconPath) {
        setFileListIcon([
          {
            uid: "-1",
            name: "",
            status: "done",
            url: `${process.env.REACT_APP_FILE}/${editData.iconPath}`,
          },
        ]);
      } else {
        setFileListIcon();
      }

      if (editData?.backgroundPath) {
        setfileListBackground([
          {
            uid: "-1",
            name: "",
            status: "done",
            url: `${process.env.REACT_APP_FILE}/${editData.backgroundPath}`,
          },
        ]);
      } else {
        setfileListBackground();
      }
    } else {
      form.resetFields();
      setFileListIcon();
      setfileListBackground();
    }
  }, [isOpen, editData, form]);

  const onFinish = async (fieldValues) => {
    const attachFilesPromises = [objectUrlIcon, objectUrlBackround].map((e) => {
      return postFile(e);
    });
    const [res1, res2] = await Promise.all(attachFilesPromises);

    let objectPath = {
      iconPath: res1?.data?.filePath,
      backgroundPath: res2?.data?.filePath,
    };

    Object.keys(objectPath).forEach((key) => {
      if (!objectPath[key]) delete objectPath[key];
    });

    await actionTopic({
      ...fieldValues,
      backgroundPath: editData?.backgroundPath,
      iconPath: editData?.iconPath,
      ...objectPath,
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Tải lên</div>
    </div>
  );

  const beforeUploadIcon = (file) => {
    const isVideo = file.type.startsWith("image/");
    if (!isVideo) {
      message.error("Chỉ được tải hình ảnh lên");
      return Upload.LIST_IGNORE;
    }

    const isLt2GB = file.size / 1024 / 1024 < 2;
    if (!isLt2GB) {
      message.error("Hình ảnh tải lên phải bé hơn 2MB");
      return Upload.LIST_IGNORE;
    }

    setObjectUrlIcon(file);
    setFileListIcon();
    setEditData((prev) => ({
      ...prev,
      iconPath: null
    }));

    return false;
  };

  const beforeUploadBackground = (file) => {
    const isVideo = file.type.startsWith("image/");
    if (!isVideo) {
      message.error("Chỉ được tải hình ảnh lên");
      return Upload.LIST_IGNORE;
    }

    const isLt2GB = file.size / 1024 / 1024 < 2;
    if (!isLt2GB) {
      message.error("Hình ảnh tải lên phải bé hơn 2MB");
      return Upload.LIST_IGNORE;
    }

    setObjectUrlBackround(file);
    setfileListBackground();
    setEditData((prev) => ({
      ...prev,
      backgroundPath: null
    }));

    return false;
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleRemoveIcon = () => {
    setFileListIcon();
    setObjectUrlIcon();

    setEditData((prev) => ({
      ...prev,
      iconPath: null
    }));
  }

  const handleRemoveBackground = () => {
    setfileListBackground();
    setObjectUrlBackround();

    setEditData((prev) => ({
      ...prev,
      backgroundPath: null
    }));
  }

  return (
    <Modal
      title={title}
      open={isOpen}
      width="600px"
      onCancel={() => {
        closeModal();
        form.resetFields();
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ isBlock: false }}
      >
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label="Tên chủ đề"
          name="topicName"
          rules={[{ required: true, message: "Vui lòng không để trống." }]}
        >
          <Input placeholder="Nhập tên chủ đề..." />
        </Form.Item>
        <Form.Item
          label="Thể loại"
          name="typeId"
          rules={[{ required: true, message: "Vui lòng không để trống." }]}
        >
          <Select
            allowClear
            showSearch
            placeholder="Chọn thể loại"
            onChange={(value) => {}}
            options={typePage?.map((item) => {
              return {
                value: item.id,
                label: item.typeName,
              };
            })}
            filterOption={(input, option) =>
              (option?.children ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          ></Select>
        </Form.Item>
        <div className="flex gap-8">
          {previewImage && (
            <Image
              wrapperStyle={{ display: "none" }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(""),
              }}
              src={previewImage}
            />
          )}
          <Form.Item label="Biểu tượng" name="logo">
            <Upload
              action={false}
              listType="picture-card"
              maxCount={1}
              fileList={fileListIcon}
              onPreview={handlePreview}
              onRemove={handleRemoveIcon}
              beforeUpload={beforeUploadIcon}
            >
              {uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item label="Hình nền" name="background">
            <Upload
              action={false}
              listType="picture-card"
              maxCount={1}
              onPreview={handlePreview}
              onRemove={handleRemoveBackground}
              fileList={fileListBackground}
              beforeUpload={beforeUploadBackground}
            >
              {uploadButton}
            </Upload>
          </Form.Item>
        </div>
        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            className="w-[105px] h-[38px] inline-flex justify-center items-center px-[24px] py-[10px] text-[16px] font-semibold"
          >
            {buttonOk}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default UserActionForm;
