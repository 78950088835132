import { addIntroduce, getListTypes, postFile } from "@/services/base";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import uploadPlugin from "@/utils/editor/uploadPlugin";
import { pageCMS } from "@/utils/const";

function CreateIntroduce() {
  const [loading, setLoading] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [draft, setDraft] = useState("");

  const nav = useNavigate();

  useEffect(() => {
    const getTypePage = async () => {
      try {
        const page = pageCMS.find((item) => item.pageName === "Introduce");
        if (page) {
          const res = await getListTypes({ cMSPageId: page.id });
          setTypeList(res.data);
        }
      } catch (error) {}
    };
    getTypePage();
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      data.contentDetail = draft;
      const res = await addIntroduce(data);
      if (res.isError) {
        message.error(res.message);
        setLoading(false);
        return;
      }

      message.success(res.message);
      nav("/introduce");
    } catch (error) {
      console.error("Error create introduce:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row>
        <Flex
          className="cursor-pointer mr-4 hover:opacity-80"
          align="center"
          onClick={() => {
            nav(-1);
          }}
        >
          <ArrowLeftOutlined />
        </Flex>
        <p className="text-xl font-semibold">Thêm mục giới thiệu</p>
      </Row>
      <div className="py-6">
        <Form onFinish={onSubmit} layout="vertical">
          <Form.Item
            name="title"
            label={<p className="font-semibold">Tiêu đề</p>}
            rules={[
              {
                required: true,
                message: "Tiêu đề không được bỏ trống",
              },
              {
                max: 500,
                message: "Tiêu đề không được quá 500 ký tự",
              },
            ]}
          >
            <Input
              size="large"
              className="rounded-none"
              placeholder="Nhập tiêu đề..."
            />
          </Form.Item>
          <Row>
            <Col span={24}>
              <Form.Item
                name="description"
                label={<p className="font-semibold">Mô tả cho tiêu đề</p>}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập mô tả cho tiêu đề!",
                  },
                ]}
              >
                <Input.TextArea
                  className="rounded-none"
                  size="large"
                  rows={5}
                  placeholder="Nhập mô tả cho tiêu đề..."
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                className="w-[300px]"
                name="typeId"
                label={<p className="font-semibold">Thể loại</p>}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn thể loại",
                  },
                ]}
              >
                <Select placeholder="Lựa chọn thể loại">
                  {typeList.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.typeName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="titleIntroduce"
            label={<p className="font-semibold">Tiêu đề cho nội dung</p>}
            rules={[
              {
                required: true,
                message: "Tiêu đề cho nội dung không được bỏ trống",
              },
              {
                max: 500,
                message: "Tiêu đề không được quá 500 ký tự",
              },
            ]}
          >
            <Input
              size="large"
              className="rounded-none"
              placeholder="Nhập tiêu đề cho nội dung..."
            />
          </Form.Item>
          <Form.Item
            name="subTitleIntroduce"
            label={<p className="font-semibold">Tiêu đề phụ cho nội dung</p>}
            rules={[
              {
                max: 500,
                message: "Tiêu đề phụ không được quá 500 ký tự",
              },
            ]}
          >
            <Input
              size="large"
              className="rounded-none"
              placeholder="Nhập tiêu đề phụ cho nội dung..."
            />
          </Form.Item>
          <Row>
            <Col span={24}>
              <Form.Item
                name="contentIntroduce"
                label={<p className="font-semibold">Nội dung</p>}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập nội dung!",
                  },
                ]}
              >
                <Input.TextArea
                  className="rounded-none"
                  size="large"
                  rows={5}
                  placeholder="Nhập nội dung..."
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
            <Form.Item
                name="contentDetail"
                label={<p className="font-semibold">Nội dung xem thêm</p>}
              >
                <CKEditor
                  config={{ extraPlugins: [uploadPlugin] }}
                  editor={Editor}
                  onReady={(editor) => {}}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                  onChange={(event, editor) => {
                    setDraft(editor.getData());
                  }}
                  data={draft}
                />
              </Form.Item>
            </Col>
          </Row>
          {loading ? (
            <Button disabled type="primary" htmlType="submit" className="mt-2">
              <LoadingOutlined />
              Thêm mục giới thiệu
            </Button>
          ) : (
            <Button
              style={{ backgroundColor: "#FF9900" }}
              type="primary"
              htmlType="submit"
              className="mt-2"
            >
              Thêm mục giới thiệu
            </Button>
          )}
          <Button
            type="primary"
            danger
            className="ml-2"
            onClick={() => nav(-1)}
          >
            Hủy bỏ
          </Button>
        </Form>
      </div>
    </>
  );
}

export default CreateIntroduce;
