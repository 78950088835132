import DataTable from "@/components/common/DataTable";
import TotalCount from "@/components/common/TotalCount";
import TitleInstructSearch from "@/components/titleInstruct/TitleInstructSearch";
import {
  deleteTitleInstruct,
  getListInstructs,
  getListTitleInstruct,
} from "@/services/base";
import { routes } from "@/utils/const";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, Tooltip, message } from "antd";
import debounce from "debounce";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";

function TitleInstruct() {
  document.title = "Hướng dẫn chính";
  const [listTitleInstructs, setListTitleInstructs] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [params, setParams] = useState({
    pageIndex: 1,
    pageSize: 10,
    total: 0,
  });

  const getTitleInstructs = async (searchParams) => {
    try {
      setLoading(true);
      const res = await getListTitleInstruct(searchParams);
      if (res.code === 200 && res.error === 0) {
        setListTitleInstructs(res.data);
        setParams({
          textSearch: params.textSearch,
          pageIndex: res.pagination.pageIndex,
          pageSize: res.pagination.pageSize,
          total: res.pagination.total,
        });
      }
    } catch (error) {
      console.error("Error fetching TitleInstructs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTitleInstructs(params);
  }, [params.pageIndex, params.pageSize, params.textSearch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchIntroduce = useCallback(
    debounce((value) => {
      setParams((prev) => ({
        ...prev,
        textSearch: value,
        pageIndex: 1,
      }));
    }, 300),
    []
  );

  const onSearch = (value) => {
    debouncedFetchIntroduce(value);
  };

  const onchangePage = (page, pageSize) => {
    setParams((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize,
    }));
  };

  const handleDelete = async (record) => {
    Modal.confirm({
      title: "Xác nhận xóa",
      content: `Bạn có chắc chắn muốn xóa mục tiêu đề hướng dẫn "${record.title}"?`,
      okText: "Xóa",
      okType: "danger",
      cancelText: "Hủy",
      onOk: async () => {
        try {
          setLoading(true);
          const res = await deleteTitleInstruct(record.id);
          if (!res.isError) {
            message.success(res.message);
            getTitleInstructs();
          } else {
            message.error(res.message);
          }
        } catch (error) {
          console.error("Error deleting Instruct:", error);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const columns = [
    {
      title: "Tiêu đề",
      dataIndex: "title",
    },
    {
      title: "Thời gian tạo",
      dataIndex: "createdDate",
      render: (value) => moment(value + "z").format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Thời gian cập nhật",
      dataIndex: "modifiedDate",
      render: (value) => moment(value + "z").format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) => {
        return (
          <div className="flex gap-2">
            {/* <Tooltip title={"Xem chi tiết"}>
              <Button
                style={{
                  backgroundColor: "#FF9900",
                }}
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
                size="small"
                onClick={() =>
                  navigate(`${routes.TITLE_INSTRUCT}/${record.id}`)
                }
              />
            </Tooltip> */}
            <Tooltip title={"Chỉnh sửa"}>
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() =>
                  navigate(`${routes.TITLE_INSTRUCT}/update/${record.id}`)
                }
              />
            </Tooltip>
            <Tooltip title={"Xóa"}>
              <Button
                type="primary"
                shape="circle"
                danger
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => handleDelete(record)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <TitleInstructSearch onSearch={onSearch} />
      <TotalCount
        text="tiêu đề hướng dẫn"
        loading={loading}
        total={params.total}
      />
      <Divider />
      <DataTable
        columns={columns}
        dataSource={listTitleInstructs.map((u) => ({ ...u, key: u.id })) || []}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={params.total}
        loading={loading}
        onPageChange={onchangePage}
      />
    </div>
  );
}

export default TitleInstruct;
