import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Divider,
  message,
  Modal,
  Space,
  Tag,
  Tooltip,
  Image,
} from "antd";
import DataTable from "@/components/common/DataTable";
import moment from "moment";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import TotalCount from "@/components/common/TotalCount";
import { deleteNews, getListNews } from "@/services/base";
import NewsFilterForm from "@/components/news/NewsFilterForm";
import { useNavigate } from "react-router-dom";
import { routes } from "@/utils/const";

const News = () => {
  document.title = "Quản lý tin tức";
  const [loading, setLoading] = useState(false);
  const [listNews, setListNews] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [params, setParams] = useState({
    textSearch: "",
    type: "",
    pageIndex: 1,
    pageSize: 10,
    total: 0,
  });

  const navigate = useNavigate();

  const fetchNews = async (searchParams) => {
    try {
      setLoading(true);
      const res = await getListNews(searchParams);
      if (!res.isError) {
        setListNews(res.data);
        setTotalPage(res?.pagination?.total);
      }
    } catch (error) {
      console.error("Error fetching news:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews(params);
  }, [params]);

  const onSearch = (value) => {
    setParams((prev) => ({
      ...prev,
      ...value,
      pageIndex: 1,
    }));
  };

  const onchangePage = (page, pageSize) => {
    setParams((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize,
    }));
  };

  const handleDelete = async (record) => {
    Modal.confirm({
      title: "Xác nhận xóa",
      content: `Bạn có chắc chắn muốn xóa tin tức "${record.title}"?`,
      okText: "Xóa",
      okType: "danger",
      cancelText: "Hủy",
      onOk: async () => {
        try {
          setLoading(true);
          const res = await deleteNews(record.id);
          if (!res.isError) {
            message.success(res.message);
            await fetchNews({
              pageIndex: 1,
              pageSize: 10,
            });
          } else {
            message.error(res.message);
          }
        } catch (error) {
          console.error("Error deleting news:", error);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const columns = [
    {
      title: "Hình ảnh",
      dataIndex: "imagePath",
      render: (value) => (
        <Image
          src={process.env.REACT_APP_FILE + "/" + value}
          alt="ảnh"
          style={{ width: "100px", height: "100px", objectFit: "cover" }}
        />
      ),
    },
    {
      title: "Nhãn",
      dataIndex: "enumFlag",
      render: (value) => (value !== null ? value.flagName : "--"),
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
    },
    {
      title: "Chủ đề",
      dataIndex: "topics",
      render: (rc) => {
        return rc?.map((topic) => <Tag>{topic.topicName}</Tag>);
      },
    },
    {
      title: "Ngày phát hành",
      dataIndex: "publishDate",
      render: (item) =>
        item ? moment(item + "z").format("DD/MM/YYYY HH:mm") : "--",
    },
    {
      title: "Trạng thái",
      dataIndex: "isBlock",
      render: (item) =>
        item ? <Tag>Riêng tư</Tag> : <Tag color="green">Công khai</Tag>,
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) => {
        return (
          <Space>
            <Tooltip title={"Xem chi tiết"}>
              <Button
                style={{
                  backgroundColor: "#FF9900",
                }}
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
                size="small"
                onClick={() => navigate(`${routes.NEWS}/${record.id}`)}
              />
            </Tooltip>
            <Tooltip title={"Chỉnh sửa"}>
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => navigate(`/news/edit/${record.id}`)}
              />
            </Tooltip>
            <Tooltip title={"Xóa"}>
              <Button
                type="primary"
                shape="circle"
                danger
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => handleDelete(record)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <NewsFilterForm onSearch={onSearch} />
      <TotalCount text="tin tức" loading={loading} total={totalPage} />
      <Divider />
      <DataTable
        columns={columns}
        dataSource={listNews.map((u) => ({ ...u, key: u.id })) || []}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={totalPage}
        loading={loading}
        onPageChange={onchangePage}
      />
    </div>
  );
};
export default News;
