import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import uploadPlugin from "@/utils/editor/uploadPlugin";
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  message,
  Row,
  Select,
  Upload,
  DatePicker,
} from "antd";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { createNews, getListTopics, postFile } from "@/services/base";
import { StatusResponse, listTypes } from "@/utils/const";

const CreateNews = () => {
  const [draft, setDraft] = useState("");
  const [objectUrlImage, setObjectUrlImage] = useState(null);
  const [messageUpload, setMessageUpload] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [datePicker, setDatePicker] = useState(null);
  const [topics, setTopics] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const res = await getListTopics({ typeId: listTypes.NEWS });
        if (!res.isError) {
          setTopics(res.data);
        }
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };
    fetchTopics();
  }, []);

  const onSubmit = async (data) => {
    if (!objectUrlImage) {
      setMessageUpload("Vui lòng tải lên hình ảnh");
      return;
    }

    try {
      setLoading(true);
      data.newsContent = draft;

      const resUpload = await postFile(objectUrlImage);
      if (resUpload.isError) {
        message.error(res.message);
        setLoading(false);
        return;
      }

      data.imagePath = resUpload.data.filePath;
      data.publishDate = datePicker ? datePicker.toISOString() : null;

      const res = await createNews(data);
      if (res.isError) {
        message.error(res.message);
        setLoading(false);
        return;
      }

      message.success(res.message);
      nav("/news");
    } catch (error) {
      console.error("Error create news:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (info) => {
    const { file } = info;
    if (file.originFileObj) {
      const objectUrlImage = URL.createObjectURL(file.originFileObj);
      setObjectUrlImage(file.originFileObj);
      setImageUrl(objectUrlImage);
    }
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("Chỉ được tải ảnh lên");
      return Upload.LIST_IGNORE;
    }

    const isLt3M = file.size / 1024 / 1024 < 3;
    if (!isLt3M) {
      message.error("Ảnh upload lên phải bé hơn 3MB");
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const handleDateChange = (date) => {
    setDatePicker(date);
  };

  return (
    <div>
      <Row>
        <Flex
          className="cursor-pointer mr-4 hover:opacity-80"
          align="center"
          onClick={() => {
            nav(-1);
          }}
        >
          <ArrowLeftOutlined />
        </Flex>
        <p className="text-xl font-semibold">Tạo tin tức</p>
      </Row>
      <div className="py-6">
        <Form onFinish={onSubmit} layout="vertical">
          <Form.Item
            name="title"
            label={<p className="font-semibold">Tiêu đề</p>}
            rules={[
              {
                required: true,
                message: "Tiêu đề không được bỏ trống",
              },
            ]}
          >
            <Input
              size="large"
              className="rounded-none"
              placeholder="Nhập tiêu đề..."
            />
          </Form.Item>
          <Row>
            <Col span={6}>
              <Form.Item
                className="w-[300px]"
                name="flagId"
                label={<p className="font-semibold">Nhãn</p>}
              >
                <Select allowClear placeholder="Lựa chọn nhãn" size="large">
                  <Select.Option value={1}>Sự kiện đang diễn ra</Select.Option>
                  <Select.Option value={2}>Bài viết nổi bật</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="w-[300px]"
                name="topics"
                label={<p className="font-semibold">Chủ đề</p>}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn chủ đề cho tin tức!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Lựa chọn chủ đề"
                  size="large"
                  options={topics?.map((topic) => ({
                    value: topic.id,
                    label: topic.topicName,
                  }))}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item
                name="publishDate"
                className="w-[300px]"
                label={<p className="font-semibold">Ngày phát hành</p>}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn ngày phát hành cho tin tức!",
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  allowClear={false}
                  className="w-full"
                  size="large"
                  onChange={handleDateChange}
                  value={datePicker}
                  showTime
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                className="w-[300px]"
                name="isBlock"
                label={<p className="font-semibold">Trạng thái</p>}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn trạng thái cho tin tức!",
                  },
                ]}
              >
                <Select
                  placeholder="Đặt trạng thái cho tin tức"
                  size="large"
                  options={[
                    {
                      key: 1,
                      label: "Công khai",
                      value: false,
                    },
                    {
                      key: 2,
                      label: "Riêng tư",
                      value: true,
                    },
                  ]}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="introduce"
                label={<p className="font-semibold">Giới thiệu</p>}
              >
                <Input.TextArea
                  className="rounded-none"
                  size="large"
                  rows={5}
                  placeholder="Giới thiệu cho tin tức này"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="imagePath"
                label={<p className="font-semibold">Hình ảnh</p>}
              >
                <img src={imageUrl} alt="" className="max-w-full" />
                <Upload
                  className="block"
                  action={false}
                  listType="picture"
                  onChange={handleChange}
                  maxCount={1}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                >
                  <Button className="mt-2" icon={<UploadOutlined />}>
                    Tải lên hình ảnh
                  </Button>
                </Upload>
                <div className="text-[#ff4d4f] text-sm mt-1">
                  {messageUpload}
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="newsContent"
                label={<p className="font-semibold">Nội dung</p>}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập nội dung cho tin tức!",
                  },
                ]}
              >
                <CKEditor
                  config={{ extraPlugins: [uploadPlugin] }}
                  editor={Editor}
                  onReady={(editor) => {}}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                  onChange={(event, editor) => {
                    setDraft(editor.getData());
                  }}
                  data={draft}
                />
              </Form.Item>
            </Col>
          </Row>
          {loading ? (
            <Button disabled type="primary" htmlType="submit" className="mt-2">
              <LoadingOutlined />
              Tạo tin tức
            </Button>
          ) : (
            <Button
              style={{ backgroundColor: "#FF9900" }}
              type="primary"
              htmlType="submit"
              className="mt-2"
            >
              Tạo tin tức
            </Button>
          )}
          <Button
            type="primary"
            danger
            className="ml-2"
            onClick={() => nav(-1)}
          >
            Hủy bỏ
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default CreateNews;
