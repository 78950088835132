import { Button, DatePicker, Form, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";

const UserActionForm = ({ modalState, closeModal, actionUser }) => {
  const { isOpen, isCreate, title, buttonOk, editData } = modalState;
  const [form] = Form.useForm();
  const [datePicker, setDatePicker] = useState(null);

  useEffect(() => {
    if (editData) {
      setDatePicker(editData.dateOfBirth ? moment(editData.dateOfBirth) : null);
      form.setFieldsValue({
        ...editData,
        dateOfBirth: editData.dateOfBirth ? moment(editData.dateOfBirth) : null,
      });
    } else {
      form.resetFields();
      setDatePicker(null);
    }
  }, [isOpen, editData, form]);

  const onFinish = async (fieldValues) => {
    await actionUser({
      ...fieldValues,
      dateOfBirth: datePicker ? datePicker.toISOString() : null,
    });
  };

  const handleDateChange = (date) => {
    setDatePicker(date);
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      width="600px"
      onCancel={() => {
        closeModal();
        form.resetFields();
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ isBlock: false }}
      >
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label="Tài khoản"
          name="userName"
          rules={[
            { required: true, message: "Vui lòng không để trống." },
            {
              pattern: /^[a-zA-Z0-9_]+$/,
              message: "Tên đăng nhập chỉ bao gồm chữ và số.",
            },
          ]}
        >
          <Input disabled={!isCreate} placeholder="Nhập tài khoản..." />
        </Form.Item>
        <Form.Item
          label="Mật khẩu"
          name="password"
          rules={[{ required: isCreate, message: "Vui lòng không để trống." }]}
        >
          <Input.Password placeholder="Nhập mật khẩu" />
        </Form.Item>
        <Form.Item
          label="Họ và tên"
          name="fullName"
          rules={[{ required: isCreate, message: "Vui lòng không để trống." }]}
        >
          <Input placeholder="Nhập họ và tên" />
        </Form.Item>
        <Form.Item label="Ngày sinh" name="dateOfBirth">
          <DatePicker
            format="DD/MM/YYYY"
            allowClear={false}
            className="w-full"
            size="large"
            onChange={handleDateChange}
            value={datePicker}
            disabledDate={(current) => {
              return current && current.valueOf() > Date.now();
            }}
          />
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          name="phoneNumber"
          rules={[
            {
              pattern: /^[0-9]{10,15}$/,
              message: "Vui lòng nhập số điện thoại hợp lệ!",
            },
          ]}
        >
          <Input placeholder="Nhập số điện thoại" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="userEmail"
          rules={[{ type: "email", message: "Địa chỉ email không hợp lệ!" }]}
        >
          <Input placeholder="Nhập email" />
        </Form.Item>
        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            className="w-[105px] h-[38px] inline-flex justify-center items-center px-[24px] py-[10px] text-[16px] font-semibold"
          >
            {buttonOk}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default UserActionForm;
