import axios from "axios"

function uploadAdapter(loader, token) {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file;
            const response = await axios.request({
              method: "POST",
              url: `${process.env.REACT_APP_API_URL}/fileupload/uploadfile`,
              data: {
                file: file
              },
              headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${token}`
              }
            })
            resolve({
              default: `${process.env.REACT_APP_FILE}/${response.data.data.filePath}`
            })
          } catch (error) {
            reject("Error uploading file");
          }
        })
      },
      abort: () => { }
    }
}

export default uploadAdapter