import "./index.css";
import { Suspense } from "react";
import Providers from "@/components/providers";
import { Row, Spin } from "antd";

function App() {
  return (
    <Suspense fallback={
      <Row
        style={{ height: "100vh", width: "100vw" }}
        justify="center"
        align="middle"
      >
        <Spin spinning />
      </Row>
    }>
      <Providers />
    </Suspense>
  );
}

export default App;
