import { Flex, Form, Input, Select } from "antd";
import React, { useState, useCallback } from "react";
import AddButton from "@/components/common/AddButton";
import debounce from "debounce";

function TypeFilterForm({ pagesCmsName, createType, onSearch }) {
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearch = useCallback(
    debounce((e) => {
      const value = e.target.value;

      setSearchTerm(value);
      onSearch({ textSearch: value });
    }, 300),
    []
  );

  const handleSearchTerm = (e) => {
    debouncedSearch(e);
  };

  const handleTypeSearchChange = (value) => {
    onSearch({ cMSPageId: value });
  };
  return (
    <Flex direction="column" justify="space-between">
      <Form className="flex flex-wrap gap-3 items-center">
        <Form.Item name="textSearch" className="w-[300px] mb-0">
          <Input
            placeholder="Tìm kiếm thể loại..."
            allowClear
            value={searchTerm}
            onChange={handleSearchTerm}
          />
        </Form.Item>
        <Select
          allowClear
          showSearch
          placeholder="Chọn trang"
          style={{
            minWidth: "200px",
          }}
          onChange={(e) => handleTypeSearchChange(e)}
          options={pagesCmsName?.map((item) => {
            return {
              value: item.id,
              label: item.pageName,
            };
          })}
          filterOption={(input, option) =>
            (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
          }
        ></Select>
      </Form>
      <AddButton text="Thêm thể loại" onClick={createType} />
    </Flex>
  );
}

export default TypeFilterForm;
