import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import uploadPlugin from "@/utils/editor/uploadPlugin";
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  message,
  Row,
  Select,
  Upload,
  DatePicker,
  Spin,
} from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "@/utils/const";
import {
  getListTopics,
  getNewsDetail,
  postFile,
  updateNews,
} from "@/services/base";
import { StatusResponse, listTypes } from "@/utils/const";
import moment from "moment";
import dayjs from "dayjs";

const EditNews = () => {
  const [draft, setDraft] = useState("");
  const [loading, setLoading] = useState(false);
  const [topics, setTopics] = useState([]);
  const [datePicker, setDatePicker] = useState(null);
  const [objectUrlImage, setObjectUrlImage] = useState(null);
  const [isChangeUpload, setIsChangeUpload] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const nav = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();

  const fetchTopics = async () => {
    try {
      const res = await getListTopics({ typeId: listTypes.NEWS });
      if (!res.isError) {
        setTopics(res.data);
      }
    } catch (error) {
      console.error("Error fetching topic:", error);
    }
  };

  const fetchNews = async () => {
    if (!id) return;
    try {
      const res = await getNewsDetail(id);
      if (!res.isError) {
        const news = res.data;
        let values = {
          id: news.id,
          title: news.title,
          topics: news.topics.map((topic) => {
            return {
              label: topic.topicName,
              value: topic.id,
            };
          }),
          introduce: news.introduce,
          imagePath: news.imagePath,
          publishDate: dayjs(news.publishDate + "z"),
          isBlock: news.isBlock,
        };

        if (news.flagId) {
          values.flagId = news.flagId;
        }

        form.setFieldsValue(values);
        setDraft(news.newsContent);
        setDatePicker(news.publishDate);
        setObjectUrlImage(news.imagePath);
        setImageUrl(process.env.REACT_APP_FILE + "/" + news.imagePath);
      }
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchTopics();
      await fetchNews();
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      data.newsContent = draft;
      data.topics = data.topics.map((topic) =>
        topic.value ? topic.value : topic
      );

      if (isChangeUpload) {
        const resUpload = await postFile(objectUrlImage);
        if (!resUpload.isError) {
          data.imagePath = resUpload.data.filePath;
        } else {
          setLoading(false);
          message.error(res.message);
          return;
        }
      }

      const res = await updateNews(data);

      if (!res.isError) {
        message.success(res.message);
        await fetchNews();
      } else {
        message.error(res.message);
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error("Error create news:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (info) => {
    const { file } = info;

    if (file.originFileObj) {
      const objectUrlImage = URL.createObjectURL(file.originFileObj);
      setObjectUrlImage(file.originFileObj);
      setImageUrl(objectUrlImage);
      setIsChangeUpload(true);
    }
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("Chỉ được tải ảnh lên");
      return Upload.LIST_IGNORE;
    }

    const isLt3M = file.size / 1024 / 1024 < 3;
    if (!isLt3M) {
      message.error("Ảnh upload lên phải bé hơn 3MB");
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const handleDateChange = (date) => {
    setDatePicker(date);
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-h-[360px] flex items-center justify-center ">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Row>
            <Flex
              className="cursor-pointer mr-4 hover:opacity-80"
              align="center"
              onClick={() => {
                nav(-1);
              }}
            >
              <ArrowLeftOutlined />
            </Flex>
            <p className="text-xl font-semibold">Cập nhật tin tức</p>
          </Row>
          <div className="py-6">
            <Form form={form} onFinish={onSubmit} layout="vertical">
              <Form.Item name="id" hidden>
                <Input hidden />
              </Form.Item>
              <Form.Item
                name="title"
                label={<p className="font-semibold">Tiêu đề</p>}
                rules={[
                  {
                    required: true,
                    message: "Tiêu đề không được bỏ trống",
                  },
                ]}
              >
                <Input
                  size="large"
                  className="rounded-none"
                  placeholder="Nhập tiêu đề..."
                />
              </Form.Item>
              <Row>
                <Col span={6}>
                  <Form.Item
                    className="w-[300px]"
                    name="flagId"
                    label={<p className="font-semibold">Nhãn</p>}
                  >
                    <Select allowClear placeholder="Lựa chọn nhãn" size="large">
                      <Select.Option value={1}>
                        Sự kiện đang diễn ra
                      </Select.Option>
                      <Select.Option value={2}>Bài viết nổi bật</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="w-[300px]"
                    name="topics"
                    label={<p className="font-semibold">Chủ đề</p>}
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn chủ đề cho tin tức!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Lựa chọn chủ đề"
                      size="large"
                      options={topics?.map((topic) => ({
                        value: topic.id,
                        label: topic.topicName,
                      }))}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Form.Item
                    className="w-[300px]"
                    name="publishDate"
                    label={<p className="font-semibold">Ngày phát hành</p>}
                  >
                    <DatePicker
                      format="DD/MM/YYYY HH:mm"
                      allowClear={false}
                      className="w-full"
                      size="large"
                      onChange={handleDateChange}
                      value={datePicker}
                      showTime
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="w-[300px]"
                    name="isBlock"
                    label={<p className="font-semibold">Trạng thái</p>}
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn trạng thái cho tin tức!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Đặt trạng thái cho tin tức"
                      size="large"
                      options={[
                        {
                          key: 1,
                          label: "Công khai",
                          value: false,
                        },
                        {
                          key: 2,
                          label: "Riêng tư",
                          value: true,
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="introduce"
                    label={<p className="font-semibold">Giới thiệu</p>}
                  >
                    <Input.TextArea
                      className="rounded-none"
                      size="large"
                      rows={5}
                      placeholder="Giới thiệu cho tin tức này"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="imagePath"
                    label={<p className="font-semibold">Hình ảnh</p>}
                  >
                    <img src={imageUrl} alt="" className="max-w-full" />
                    <Upload
                      className="block"
                      action={false}
                      listType="picture"
                      onChange={handleChange}
                      maxCount={1}
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                    >
                      <Button className="mt-2" icon={<UploadOutlined />}>
                        Tải lên hình ảnh
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="newsContent"
                label={<p className="font-semibold">Nội dung</p>}
              >
                <CKEditor
                  config={{ extraPlugins: [uploadPlugin] }}
                  editor={Editor}
                  onReady={(editor) => {}}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                  onChange={(event, editor) => {
                    setDraft(editor.getData());
                  }}
                  data={draft}
                />
              </Form.Item>
              <Button
                style={{ backgroundColor: "#FF9900" }}
                type="primary"
                htmlType="submit"
                className="mt-2"
              >
                Cập nhật tin tức
              </Button>
              <Button
                type="primary"
                danger
                className="ml-2"
                onClick={() => nav(routes.NEWS)}
              >
                Hủy bỏ
              </Button>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default EditNews;
