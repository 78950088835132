import { Flex, Form, Input } from "antd";
import { useState } from "react";
import AddButton from "../../common/AddButton";
import { useNavigate } from "react-router";

function SubIntroduceSearch({ onSearch }) {
  const nav = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <Flex direction="column" justify="space-between">
      <Form className="flex flex-wrap gap-3 items-center">
        <Form.Item name="textSearch" className="w-[300px] mb-0">
          <Input
            placeholder="Tìm kiếm tiêu đề giới thiệu..."
            allowClear
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Form.Item>
      </Form>
      <AddButton
        text="Thêm nội dung bổ sung"
        onClick={() => {
          nav("/content-introduce/add");
        }}
      />
    </Flex>
  );
}

export default SubIntroduceSearch;
