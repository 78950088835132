import {
  Typography,
  Divider,
  Row,
  Col,
  Image,
  Spin,
  Flex,
  Button,
  Modal,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import moment from "moment";
import { getDetailInstruct, deleteInstruct } from "@/services/base";
import { StatusResponse, routes } from "@/utils/const";
import GuideActionForm from "@/components/guides/GuideActionForm";

const { Title, Text } = Typography;

const GuideDetail = () => {
  document.title = "Thông tin chi tiết hướng dẫn";
  const nav = useNavigate();
  const { id } = useParams();
  const [guideDetail, setGuideDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState({
    isOpen: false,
    isCreate: true,
    title: "Chỉnh sửa hướng dẫn",
    buttonOk: "Cập nhật",
    editData: null,
  });

  useEffect(() => {
    fetchGuideDetailData();
  }, []);

  const fetchGuideDetailData = async () => {
    try {
      setLoading(true);
      const res = await getDetailInstruct(id);
      if (!res.isError) {
        setGuideDetail(res.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching instruct:", error);
    }
  };

  const handleDelete = async (id) => {
    Modal.confirm({
      title: "Xác nhận xóa",
      content: "Bạn có chắc chắn muốn xóa hướng dẫn này này?",
      okText: "Xóa",
      okType: "danger",
      cancelText: "Hủy",
      onOk: async () => {
        try {
          setLoading(true);
          const res = await deleteInstruct(id);
          if (!res.isError) {
            message.success(res.message);
            nav(routes.GUIDES);
          } else {
            message.error(res.message);
          }
        } catch (error) {
          console.error("Error deleting instruct:", error);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const handleEdit = () => {
    setModalState({
      isOpen: true,
      isCreate: false,
      buttonOk: "Cập nhật",
      editData: {
        ...guideDetail,
      },
      title: "Chỉnh sửa hướng dẫn",
    });
  };

  const handleCloseModal = () => {
    setModalState({
      isOpen: false,
      isCreate: true,
      title: "",
      buttonOk: "",
      editData: null,
    });
  };

  const reloadData = () => {
    fetchGuideDetailData();
  };

  return (
    <div>
      {loading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Flex justify="space-between">
            <Flex align="center">
              <div
                className="flex items-center cursor-pointer mr-[10px] hover:opacity-80"
                onClick={() => {
                  nav(-1);
                }}
              >
                <ArrowLeftOutlined />
              </div>
              <Typography.Title
                level={3}
                className="pl-4 mr-4 !mb-0 font-semibold text-center"
              >
                Thông tin chi tiết hướng dẫn
              </Typography.Title>
            </Flex>
            <div className="flex items-center space-x-2">
              <Button
                style={{
                  backgroundColor: "#FF9900",
                }}
                type="primary"
                onClick={() => handleEdit()}
                className="shadow flex items-center rounded-md border px-2 py-1 space-x-1"
              >
                <span>Chỉnh sửa</span>
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => handleDelete(id)}
                className="shadow flex items-center border border-red-500 rounded-md text-red-500 px-2 py-1 space-x-1"
              >
                <span>Xóa hướng dẫn</span>
              </Button>
            </div>
          </Flex>
          <Divider />
          <Row className="mt-4">
            <Col span={5}>
              <Typography.Text className="font-bold">
                Người tạo:{" "}
              </Typography.Text>
              <Typography.Text>
                {guideDetail?.createdUser.userName}
              </Typography.Text>
            </Col>
            <Col span={5}>
              <Typography.Text className="font-bold">
                Ngày tạo:{" "}
              </Typography.Text>
              <Typography.Text>
                {moment(guideDetail?.createDate).format("DD/MM/YYYY")}
              </Typography.Text>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col span={5}>
              <Typography.Text className="font-bold">
                Người chỉnh sửa cuối:{" "}
              </Typography.Text>
              <Typography.Text>
                {guideDetail?.modifiedUser.userName
                  ? guideDetail?.modifiedUser.userName
                  : "Chưa có"}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text className="font-bold">
                Lần cập nhật gần nhất:{" "}
              </Typography.Text>
              <Typography.Text>
                {guideDetail?.modifiedDate
                  ? moment(guideDetail?.modifiedDate).format("DD/MM/YYYY")
                  : "Chưa có"}
              </Typography.Text>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col span={5}>
              <Typography.Text className="font-bold">Tiêu đề: </Typography.Text>
              <Typography.Text>{guideDetail?.title}</Typography.Text>
            </Col>
            <Col>
              <Typography.Text className="font-bold">
                Nội dung:{" "}
              </Typography.Text>
              <Typography.Text>{guideDetail?.contentInstruct}</Typography.Text>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Typography.Text className="block font-bold mb-3">
                Video hướng dẫn:{" "}
              </Typography.Text>
              <div className="flex flex-wrap gap-5">
                <video
                  width={300}
                  controls
                  src={`${process.env.REACT_APP_FILE}/${guideDetail?.videoPath}`}
                ></video>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Typography.Text className="block font-bold mb-3">
                Hình ảnh hướng dẫn:{" "}
              </Typography.Text>
              <div className="flex flex-wrap gap-5">
                {guideDetail?.images?.map((image, index) => {
                  return (
                    <div className="max-w-[250px] mb-5">
                      <div className="w-[250px] h-[250px] flex items-center justify-center flex-shrink-0 px-2 py-2 border-solid border-[1px] border-[#d9d9d9] rounded-lg">
                        <Image
                          style={{
                            maxHeight: "200px",
                          }}
                          src={`${process.env.REACT_APP_FILE}/${image?.imagePath}`}
                          alt={image?.contentImage}
                          key={index}
                        />
                      </div>
                      <Typography.Text className="block font-medium mb-0 mt-4">
                        Nội dung hình ảnh:
                      </Typography.Text>
                      <div className="justify-center  px-2 py-2 border-solid border-[1px] border-[#d9d9d9] rounded-lg mt-1">
                        {image?.contentImage}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          <Divider />
        </>
      )}
      <GuideActionForm
        modalState={modalState}
        closeModal={() => handleCloseModal()}
        reload={reloadData}
      />
    </div>
  );
};

export default GuideDetail;
