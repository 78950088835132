import { Flex, Form, Input, Select, message } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import AddButton from "@/components/common/AddButton";
import debounce from "debounce";
import {
  getListTitleInstruct,
} from "@/services/base";

function GuideFilterForm({createGuide, onSearch}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [titleInstructList, setTitleInstructList] = useState([]);

  const debouncedSearch = useCallback(
    debounce((e) => {
      const value = e.target.value;

      setSearchTerm(value);
      onSearch({ textSearch: value });
    }, 300),
    []
  );

  const handleSearchTerm = (e) => {
    debouncedSearch(e);
  };

  useEffect(() => {
    getTitleInstruct();
  }, [])

  const getTitleInstruct = async () => {
    try {
      const res = await getListTitleInstruct({ pageIndex: 1, pageSize: 100 });
      if (res.code !== 200 && res.error !== 0)
        return message.error(res?.message);

      setTitleInstructList(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleContentSearchChange = (value) => {
    onSearch({ titleId: value });
  }

  return (
    <Flex direction="column" justify="space-between">
      <Form className="flex flex-wrap gap-3 items-center">
        <Form.Item name="textSearch" className="w-[300px] mb-0">
          <Input
            placeholder="Tìm kiếm hướng dẫn..."
            allowClear
            value={searchTerm}
            onChange={handleSearchTerm}
          />
        </Form.Item>
        <Select
          allowClear
          style={{
            minWidth: "200px",
            maxWidth: "300px",
          }}
          showSearch
          placeholder="Chọn hướng dẫn chính"
          onChange={(e) => handleContentSearchChange(e)}
          filterOption={(input, option) =>
            (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
          }
        >
          {titleInstructList.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.title}
            </Select.Option>
          ))}
        </Select>
      </Form>
      <AddButton text="Thêm hướng dẫn" onClick={createGuide}/>
    </Flex>
  );
}

export default GuideFilterForm;