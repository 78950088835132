import axios from "axios";
import {
  getAccessTokenBase,
  getRefreshTokenBase,
  setAccessTokenBase,
  setRefreshTokenBase,
} from "@/utils/common";
import { authRefreshToken } from "@/services/base";
import { routes } from "@/utils/const";

const BEARER = "Bearer ";
const baseConfig = {
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
  },
};

export const instanceBase = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  ...baseConfig,
});

instanceBase.interceptors.request.use(
  function (config) {
    const accessToken = getAccessTokenBase();
    if (accessToken) {
      config.headers.Authorization = `${BEARER} ${accessToken}`;
    }
    return config;
  },
  function (error) {
    console.log("Request error: " + error);
    Promise.reject(error);
    throw new Error(error);
  }
);

// Add a response interceptor
instanceBase.interceptors.response.use(
  function (response) {
    if (response.data && response.data.code === 401) {
      const accessToken = getAccessTokenBase();
      const refreshToken = getRefreshTokenBase();
      if (!accessToken || !refreshToken) return Promise.resolve(response);

      return authRefreshToken({
        accessToken: accessToken,
        refreshToken: refreshToken,
      })
        .then((refreshResponse) => {
          setAccessTokenBase(refreshResponse.data.accessToken);
          setRefreshTokenBase(refreshResponse.data.refreshToken);

          const originalRequest = response.config;
          originalRequest.headers.Authorization = `Bearer ${refreshResponse.data.accessToken}`;
          return instanceBase(originalRequest);
        })
        .catch((refreshError) => {
          console.log("Error refreshing token:", refreshError.message);
          return response.data ? response.data : response;
        });
    } else if (response.data.code === 401) {
      localStorage.clear();
      window.location.href = routes.LOGIN;
    } else if (response.data && response.data.code >= 500) {
      window.location.href = routes.ERROR;
    }

    return response.data ? response.data : response;
  },
  function (error) {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.href = routes.LOGIN;
    }

    if (error.response?.status >= 500 || error.code === "ERR_NETWORK") {
      window.location.href = routes.ERROR;
    }

    console.log("Response error: " + error.message);
    Promise.reject(error);
    throw new Error(error);
  }
);

class APIBase {
  get = async (url, config) => {
    return instanceBase.get(url, { ...config });
  };

  post = (url, data, config) => {
    return instanceBase.post(url, data, { ...config });
  };

  put = (url, data, config) => {
    return instanceBase.put(url, data, { ...config });
  };

  patch = (url, data, config) => {
    return instanceBase.patch(url, data, { ...config });
  };

  delete = (url, config) => {
    return instanceBase.delete(url, { ...config });
  };

  postFile = (url, data, config) => {
    return instanceBase.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...config,
    });
  };
}

const setAuthorizationBase = (token) => {
  instanceBase.defaults.headers.common["Authorization"] = token.replace(
    /"/g,
    ""
  );
};

export { APIBase, setAuthorizationBase };
