import React from "react";
import { Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "@/assets/images/logo.jpg";
import { routes } from "@/utils/const";
import {
  FolderOpenOutlined,
  HomeOutlined,
  PlaySquareOutlined,
  FormOutlined,
  GoldOutlined,
  QuestionCircleOutlined,
  UnorderedListOutlined,
  UserOutlined,
  VideoCameraOutlined,
  FileImageOutlined,
  LinkOutlined,
  PlayCircleOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";

export const parentRoute = {
  [routes.HOME]: routes.HOME,
};

function Sidebar({ collapsed, setCollapsed }) {
  const navigate = useNavigate();
  const location = useLocation();
  const route = `/${location.pathname.split("/")[1]}`;
  const userInfor = useRecoilValue(infoUser);
  const isRoot = userInfor?.isRoot;

  const menuItems = [
    // {
    //   key: routes.HOME,
    //   label: "Trang chủ",
    //   icon: <HomeOutlined style={{ fontSize: "1.25rem" }} />,
    //   allow: "true",
    // },
    {
      key: routes.USERS,
      label: "Quản lý nhân viên",
      icon: <UserOutlined style={{ fontSize: "1.25rem" }} />,
      allow: isRoot,
    },
    {
      key: routes.TYPE,
      label: "Quản lý thể loại",
      icon: <UnorderedListOutlined style={{ fontSize: "1.25rem" }} />,
      allow: isRoot,
    },
    {
      key: routes.TOPIC,
      label: "Quản lý chủ đề",
      icon: <GoldOutlined style={{ fontSize: "1.25rem" }} />,
      allow: "true",
    },
    {
      key: routes.NEWS,
      label: "Quản lý tin tức",
      icon: <FormOutlined style={{ fontSize: "1.25rem" }} />,
      allow: "true",
    },
    {
      key: routes.VIDEO,
      label: "Quản lý video",
      icon: <VideoCameraOutlined style={{ fontSize: "1.25rem" }} />,
      allow: "true",
    },
    // {
    //   key: routes.IMAGES,
    //   label: "Quản lý hình ảnh",
    //   icon: <FileImageOutlined style={{ fontSize: "1.25rem" }} />,
    //   allow: "true",
    // },
    {
      key: routes.LINKS,
      label: "Quản lý đường dẫn",
      icon: <LinkOutlined style={{ fontSize: "1.25rem" }} />,
      allow: "true",
    },
    {
      label: "Quản lý hướng dẫn",
      icon: <QuestionCircleOutlined style={{ fontSize: "1.25rem" }} />,
      allow: "true",
      children: [
        {
          key: routes.TITLE_INSTRUCT,
          label: "Hướng dẫn chính",
          icon: <FolderOpenOutlined style={{ fontSize: "1.25rem" }} />,
          allow: "true",
        },
        {
          key: routes.GUIDES,
          label: "Hướng dẫn phụ",
          icon: <FolderOpenOutlined style={{ fontSize: "1.25rem" }} />,
          allow: "true",
        },
      ],
    },

    {
      label: "Quản lý giới thiệu",
      icon: <GlobalOutlined style={{ fontSize: "1.25rem" }} />,
      allow: "true",
      children: [
        {
          key: routes.INTRODUCE,
          label: "Nội dung chính",
          icon: <FolderOpenOutlined style={{ fontSize: "1.25rem" }} />,
          allow: "true",
        },
        {
          key: routes.SUB_INTRODUCE,
          label: "Nội dung bổ sung",
          icon: <FolderOpenOutlined style={{ fontSize: "1.25rem" }} />,
          allow: "true",
        },
      ],
    },
  ];

  const visibleItems = menuItems.filter((item) => item.allow);
  const pRoute = parentRoute[route];

  const onClick = (e) => {
    navigate(e.key);
  };

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      width={240}
      theme="light"
      style={{
        position: "fixed",
        left: 0,
        height: "100vh",
      }}
    >
      <div className="my-[20px] mx-auto w-[70%]">
        <a href={"/"}>
          <img className="max-w-full" src={logo} alt="" />
        </a>
      </div>
      <Menu
        style={{
          borderInline: "none",
          backgroundColor: "transparent",
        }}
        mode="inline"
        defaultOpenKeys={[pRoute]}
        defaultSelectedKeys={[pRoute]}
        selectedKeys={[route]}
        className="text-[17px] bg-transparent mt-2 font-semibold"
        onClick={onClick}
        items={visibleItems}
      />
    </Layout.Sider>
  );
}

export default Sidebar;
