//Auth
export const AUTH_LOGIN = "/auth/login";
export const AUTH_REFRESH_TOKEN = "/auth/refresh-token";
export const AUTH_CHANGE_PASS = "/auth/change-password";

// Users
export const USER = "/user";
export const USER_GET_LIST = "/user/get-page";
export const USER_UPDATE_PROFILE = "/user/update";
export const USER_CREATE = "/user/add";
export const USER_UPDATE = "/user/update";

// Type
export const TYPE = "/typepage";
export const TYPE_GET_LIST = "/typepage/get-page";
export const TYPE_CREATE = "/typepage/add";
export const TYPE_UPDATE = "/typepage/update";

// Page
export const PAGE_GET_LIST = "/pagename/gets";

// Get Page Name CMS
export const PAGE_NAME_CMS = "/pagenamecms";
export const PAGE_NAME_CMS_GET_LIST = "/pagenamecms/gets";

// Topics
export const TOPIC = "/topic";
export const TOPIC_GET_LIST = "/topic/get-page";
export const TOPIC_CREATE = "/topic/add";
export const TOPIC_UPDATE = "/topic/update";

// News
export const NEWS = "/news";
export const NEWS_GET_LIST = "/news/get-page";
export const NEWS_CREATE = "/news/add";
export const NEWS_UPDATE = "/news/update";
export const NEWS_HARD_DELETE = "/news/hard-delete";

// Videos
export const VIDEO = "/video";
export const VIDEO_GET_LIST = "/video/get-page";
export const VIDEO_CREATE = "/video/add";
export const VIDEO_UPDATE = "/video/update";
export const VIDEO_SOFT_DELETE = "/video/soft-delete";
export const VIDEO_HARD_DELETE = "/video/hard-delete";

//Images
export const IMAGE = "/image";
export const IMAGE_GET_LIST = "/image/get-page";
export const IMAGE_CREATE = "/image/add";
export const IMAGE_UPDATE = "/image/update";
export const IMAGE_SOFT_DELETE = "/image/soft-delete";
export const IMAGE_HARD_DELETE = "/image/hard-delete";

// Instructs
export const INSTRUCT = "/instruct";
export const INSTRUCT_GET_LIST = "/instruct/get-page";
export const INSTRUCT_CREATE = "/instruct/add";
export const INSTRUCT_UPDATE = "/instruct/update";

// File upload
export const UPLOAD_FILE = "/fileupload/uploadfile";

// Introduce
export const INTRODUCE = "/introduce/get-page";
export const CREATE_INTRODUCE = "/introduce/add";
export const DETAIL_INTRODUCE = "/introduce";
export const UPDATE_INTRODUCE = "/introduce/update";
export const DELETE_INTRODUCE = "/introduce";

// Sub Introduce
export const SUB_INTRODUCE = "/contentintroduce/get-page";
export const SUB_CREATE_INTRODUCE = "/contentintroduce/add";
export const SUB_DETAIL_INTRODUCE = "/contentintroduce";
export const SUB_UPDATE_INTRODUCE = "/contentintroduce/update";
export const SUB_DELETE_INTRODUCE = "/contentintroduce/soft-delete";

//Links
export const LINKS = "/link";
export const LINKS_GET_LIST = "/link/get-page";
export const LINKS_CREATE = "/link/add";
export const LINKS_UPDATE = "/link/update";
export const LINKS_HARD_DELETE = "/link/hard-delete";

// TitleInstruct
export const TITLE_INSTRUCT = "/titleinstruct";
export const TITLE_INSTRUCT_GET_LIST = "/titleinstruct/get-page";
export const TITLE_INSTRUCT_CREATE = "/titleinstruct/add";
export const TITLE_INSTRUCT_UPDATE = "/titleinstruct/update";

// link main
export const LINK_MAIN = "/maindomain"
