import { createTitleInstruct } from "@/services/base";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Form, Input, Row, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

function TitleInstructCreate() {
  document.title = "Thêm mới tiêu đề hướng dẫn";
  const [loading, setLoading] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [draft, setDraft] = useState("");

  const nav = useNavigate();

  //   useEffect(() => {
  //     const getTypePage = async () => {
  //       try {
  //         const page = pageCMS.find((item) => item.pageName === "Introduce");
  //         if (page) {
  //           const res = await getListTypes({ cMSPageId: page.id });
  //           setTypeList(res.data);
  //         }
  //       } catch (error) {}
  //     };
  //     getTypePage();
  //   });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      data.contentDetail = draft;
      const res = await createTitleInstruct(data);
      if (res.isError) {
        message.error(res.message);
        setLoading(false);
        return;
      }
      message.success(res.message);
      nav("/titleInstruct");
    } catch (error) {
      console.error("Error create title Instruct:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row>
        <Flex
          className="cursor-pointer mr-4 hover:opacity-80"
          align="center"
          onClick={() => {
            nav(-1);
          }}
        >
          <ArrowLeftOutlined />
        </Flex>
        <p className="text-xl font-semibold">Thêm mục tiêu đề hướng dẫn</p>
      </Row>
      <div className="py-6">
        <Form onFinish={onSubmit} layout="vertical">
          <Form.Item
            name="title"
            label={<p className="font-semibold">Tiêu đề</p>}
            rules={[
              {
                required: true,
                message: "Tiêu đề không được bỏ trống",
              },
              {
                max: 500,
                message: "Tiêu đề không được quá 500 ký tự",
              },
            ]}
          >
            <Input
              size="large"
              className="rounded-none"
              placeholder="Nhập tiêu đề..."
            />
          </Form.Item>
          {loading ? (
            <Button disabled type="primary" htmlType="submit" className="mt-2">
              <LoadingOutlined />
              Thêm mục tiêu đề hướng dẫn
            </Button>
          ) : (
            <Button
              style={{ backgroundColor: "#FF9900" }}
              type="primary"
              htmlType="submit"
              className="mt-2"
            >
              Thêm mục tiêu đề hướng dẫn
            </Button>
          )}
          <Button
            type="primary"
            danger
            className="ml-2"
            onClick={() => nav(-1)}
          >
            Hủy bỏ
          </Button>
        </Form>
      </div>
    </>
  );
}

export default TitleInstructCreate;
