import { Button, Flex, Form, Input, Modal, Select } from "antd";
import React, { useState, useCallback } from "react";
import AddButton from "@/components/common/AddButton";
import debounce from "debounce";

function LinkFilterForm({ typePage, createVideo, onSearch, setOpen }) {
  const [searchTerm, setSearchTerm] = useState("");


  const debouncedSearch = useCallback(
    debounce((e) => {
      const value = e.target.value;

      setSearchTerm(value);
      onSearch({ textSearch: value });
    }, 300),
    []
  );

  const handleSearchTerm = (e) => {
    debouncedSearch(e);
  };

  const handleTypeSearchChange = (typeId) => {
    onSearch({ typeId: typeId });
  };

  return (
    <>
      <Flex direction="column" justify="space-between">
        <Form className="flex flex-wrap gap-3 items-center">
          <Form.Item name="textSearch" className="w-[300px] mb-0">
            <Input
              placeholder="Tìm kiếm link..."
              allowClear
              value={searchTerm}
              onChange={handleSearchTerm}
            />
          </Form.Item>
          <Select
            allowClear
            style={{
              minWidth: "200px",
              maxWidth: "300px",
            }}
            showSearch
            placeholder="Chọn thể loại"
            onChange={(e) => handleTypeSearchChange(e)}
            filterOption={(input, option) =>
              (option?.children ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {typePage?.map((type) => (
              <Select.Option key={type.id} value={type.id}>
                {type.typeName}
              </Select.Option>
            ))}
          </Select>
        </Form>
        <Flex gap={5} align="center">
          <Button type="primary" onClick={() => setOpen(true)}>
            Cập nhật Tên miền chính
          </Button>
          <AddButton text="Thêm link" onClick={createVideo} />
        </Flex>
      </Flex>
      
    </>
  );
}

export default LinkFilterForm;
