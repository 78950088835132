import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Flex, Row, Spin, Typography } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";

function TitleInstructDetail() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const nav = useNavigate();
  return (
    <>
      {loading ? (
        <div className="w-full min-h-[360px] flex items-center justify-center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="news-detail">
          <Flex justify="space-between">
            <Flex align="center">
              <div
                className="flex items-center cursor-pointer mr-[10px] hover:opacity-80"
                onClick={() => {
                  nav(-1);
                }}
              >
                <ArrowLeftOutlined />
              </div>
              <Typography.Title
                level={3}
                className="pl-4 mr-4 !mb-0 font-semibold text-center"
              >
                {/* {introduceDetail?.title} */}
              </Typography.Title>
            </Flex>
            <div className="flex items-center space-x-2">
              <Button
                style={{
                  backgroundColor: "#FF9900",
                }}
                type="primary"
                onClick={() => nav(`/introduce/update/${id}`)}
                className="shadow flex items-center rounded-md border px-2 py-1 space-x-1"
              >
                <span>Chỉnh sửa</span>
              </Button>
              {/* <Button
            type="primary"
            danger
            onClick={() => handleDelete(id)}
            className="shadow flex items-center border border-red-500 rounded-md text-red-500 px-2 py-1 space-x-1"
          >
            <span>Xóa giới thiệu</span>
          </Button> */}
            </div>
          </Flex>
          <Divider />
          <section>
            <Row>
              <Typography.Title level={3} style={{ color: "#5d5d5d" }}>
                Thông tin giới thiệu
              </Typography.Title>
            </Row>
            <Row className="mt-4">
              <Col span={5}>
                <Typography.Text className="font-bold text-gray-700">
                  Thời gian tạo:{" "}
                </Typography.Text>
                <Typography.Text>
                  {/* {moment(introduceDetail?.createDate).format("DD/MM/YYYY")} */}
                </Typography.Text>
              </Col>
              <Col span={5}>
                <Typography.Text className="font-bold text-gray-700">
                  Thời gian chỉnh sửa:{" "}
                </Typography.Text>
                <Typography.Text>
                  {/* {moment(introduceDetail?.modifiedDate).format("DD/MM/YYYY")} */}
                </Typography.Text>
              </Col>
            </Row>
          </section>
          <Divider />
          <section>
            <Row>
              <Typography.Title level={3} style={{ color: "#5d5d5d" }}>
                Mô tả cho tiêu đề
              </Typography.Title>
            </Row>
            <Row className="mt-4">
              <Col span={24}>
                <Typography.Text>
                  {/* {introduceDetail?.description} */}
                </Typography.Text>
              </Col>
            </Row>
          </section>
          <Divider />
          <section>
            <Row>
              <Typography.Title level={3} style={{ color: "#5d5d5d" }}>
                Thể loại
              </Typography.Title>
            </Row>
            <Row className="mt-4">
              <Col span={24}>
                <Typography.Text>
                  {/* {introduceDetail?.type?.typeName} */}
                </Typography.Text>
              </Col>
            </Row>
          </section>
          <Divider />

          <section>
            <Row>
              <Typography.Title level={3} style={{ color: "#5d5d5d" }}>
                Tiêu đề cho nội dung
              </Typography.Title>
            </Row>
            <Row className="mt-4">
              <Col>{/* <div>{introduceDetail?.titleIntroduce}</div> */}</Col>
            </Row>
          </section>
          <Divider />

          <section>
            <Row>
              <Typography.Title level={3} style={{ color: "#5d5d5d" }}>
                Tiêu đề phụ cho nội dung
              </Typography.Title>
            </Row>
            <Row className="mt-4">
              <Col>
                {/* <div>{introduceDetail?.subTitleIntroduce || "Không có"}</div> */}
              </Col>
            </Row>
          </section>
          <Divider />

          <section>
            <Row>
              <Typography.Title level={3} style={{ color: "#5d5d5d" }}>
                Nội dung
              </Typography.Title>
            </Row>
            <Row className="mt-4">
              <Col>
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: introduceDetail?.contentIntroduce,
                  }}
                ></div> */}
              </Col>
            </Row>
          </section>
          <Divider />
          <Row className="mt-4">
            <Button
              style={{
                backgroundColor: "#FF9900",
              }}
              type="primary"
              onClick={() => nav(-1)}
              className="shadow flex items-center rounded-md border px-2 py-1 space-x-1"
            >
              <ArrowLeftOutlined className="mr-1" />
              Trở về
            </Button>
          </Row>
        </div>
      )}
    </>
  );
}

export default TitleInstructDetail;
