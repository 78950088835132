import { UPDATE_INTRODUCE } from "@/configs/url_api";

export const LocalStorageKey = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  SETTING_BASE: "setting_base",
  USER_INFO: "user_info",
  REF: "ref_code",
};

export const StatusResponse = {
  SUCCESS: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  REQUEST_TIMEOUT: 408,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
};

export const routes = {
  ANY: "*",
  HOME: "/home",
  LOGIN: "/login",
  USERS: "/users",
  NEWS: "/news",
  NEWS_DETAIL: "/news/:id",
  CREATE_NEWS: "/news/create",
  EDIT_NEWS: "/news/edit/:id",
  TYPE: "/types",
  TOPIC: "/topics",
  VIDEO: "/videos",
  IMAGES: "/images",
  LINKS: "/links",
  GUIDES: "/guides",
  USER_PROFILE: "/users/my-profile",
  INTRODUCE: "/introduce",
  CREATE_INTRODUCE: "/introduce/add",
  DETAIL_INTRODUCE: "/introduce/:id",
  UPDATE_INTRODUCE: "/introduce/update/:id",
  DELETE_INTRODUCE: "/introduce/:id",
  SUB_INTRODUCE: "/content-introduce",
  SUB_UPDATE_INTRODUCE: "/content-introduce/update/:id",
  SUB_CREATE_INTRODUCE: "/content-introduce/add",

  TITLE_INSTRUCT: "/titleinstruct",
  CREATE_TITLE_INSTRUCT: "/titleinstruct/add",
  DETAIL_TITLE_INSTRUCT: "/titleinstruct/:id",
  UPDATE_TITLE_INSTRUCT: "/titleinstruct/update/:id",
  DELETE_TITLE_INSTRUCT: "/titleinstruct/:id",

  ERROR: "/server-error",
};

export const pageCMS = [
  {
    id: 1,
    pageName: "Chủ đề",
  },
  {
    id: 2,
    pageName: "Tin tức",
  },
  {
    id: 3,
    pageName: "Video",
  },
  {
    id: 4,
    pageName: "Hình ảnh",
  },
  {
    id: 5,
    pageName: "Đường dẫn",
  },
  {
    id: 6,
    pageName: "Giới thiệu",
  },
  {
    id: 7,
    pageName: "Hướng dẫn",
  },
];

export const listTypes = {
  NEWS: 4,
  VIDEO: 5,
};

export const videoType = [
  {
    value: 1,
    label: "Video trang chủ",
  },
  {
    value: 2,
    label: "Video giới thiệu",
  },
];
