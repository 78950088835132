import { LocalStorageKey } from "@/utils/const";

export const getAccessTokenBase = () => {
  if (typeof window !== "undefined") {
    const access_token = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
    return access_token || null;
  }

  return null;
};

export const setAccessTokenBase = (data) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, data);
  }
};

export const setStorageUserInfo = (data) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(LocalStorageKey.USER_INFO, JSON.stringify(data));
  }
};

export const getUserInfo = () => {
  if (typeof window !== "undefined") {
    const user_info = localStorage.getItem(LocalStorageKey.USER_INFO);
    return user_info || null;
  }

  return null;
};

export const getRefreshTokenBase = () => {
  if (typeof window !== "undefined") {
    const refresh_token = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN);
    return refresh_token || null;
  }

  return null;
};

export const setRefreshTokenBase = (data) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(LocalStorageKey.REFRESH_TOKEN, data);
  }
};

export const durationTimeFormat = (d) => {
  const duration = Math.floor(d);
  const h = Math.floor(duration / 3600);
  const m = Math.floor((duration - h * 3600) / 60);
  const s = duration % 60;
  const H = h === 0 ? '' : `${h}:`;
  const M = m < 10 ? `0${m}:` : `${m}:`;
  const S = s < 10 ? `0${s}` : `${s}`;
  return H + M + S;
}