import uploadAdapter from "./uploadAdapter"
import { getAccessTokenBase } from "@/utils/common";

function uploadPlugin(editor) {
  const accessToken = getAccessTokenBase();
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader, accessToken)
  }
}

export default uploadPlugin