import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Image,
  message,
  Modal,
  Space,
  Tooltip,
  Switch,
} from "antd";
import DataTable from "@/components/common/DataTable";
import moment from "moment";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import avatar from "@/assets/images/avatar-default.png";
import UserFilterForm from "@/components/users/UserFilterForm";
import TotalCount from "@/components/common/TotalCount";
import {
  createUser,
  deleteUser,
  getListUser,
  updateUser,
} from "@/services/base";
import UserActionForm from "@/components/users/UserActionForm";
import { StatusResponse } from "@/utils/const";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import debounce from "debounce";

const Users = () => {
  document.title = "Quản lý nhân viên";
  const [loading, setLoading] = useState(false);
  const [listUser, setListUser] = useState([]);
  const [params, setParams] = useState({
    textSearch: "",
    pageIndex: 1,
    pageSize: 10,
    total: 0,
  });
  const [modalState, setModalState] = useState({
    isOpen: false,
    isCreate: true,
    title: "Tạo nhân viên",
    buttonOk: "Tạo mới",
    editData: null,
  });

  const userInfor = useRecoilValue(infoUser);
  const { isRoot } = userInfor;

  const fetchUsers = async (searchParams) => {
    try {
      setLoading(true);
      const res = await getListUser(searchParams);
      if (!res.isError) {
        setListUser(res.data);
        setParams({
          textSearch: params.textSearch,
          pageIndex: res.pagination.pageIndex,
          pageSize: res.pagination.pageSize,
          total: res.pagination.total,
        });
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(params);
  }, [params.pageIndex, params.pageSize, params.textSearch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchUsers = useCallback(
    debounce((value) => {
      setParams((prev) => ({
        ...prev,
        textSearch: value,
        pageIndex: 1,
      }));
    }, 300),
    []
  );

  const onSearch = (value) => {
    debouncedFetchUsers(value);
  };

  const onchangePage = (page, pageSize) => {
    setParams((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize,
    }));
  };

  const handleEdit = (record) => {
    setModalState({
      isOpen: true,
      isCreate: false,
      buttonOk: "Cập nhật",
      editData: record,
      title: "Chỉnh sửa nhân viên",
    });
  };

  const handleDelete = async (record) => {
    Modal.confirm({
      title: "Xác nhận xóa",
      content: `Bạn có chắc chắn muốn xóa nhân viên "${record.userName}"?`,
      okText: "Xóa",
      okType: "danger",
      cancelText: "Hủy",
      onOk: async () => {
        try {
          setLoading(true);
          const res = await deleteUser(record.id);
          if (!res.isError) {
            message.success(res.message);
            await fetchUsers({
              pageIndex: 1,
              pageSize: 10,
            });
          } else {
            message.error(res.message);
          }
        } catch (error) {
          console.error("Error deleting user:", error);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const actionUser = async (userData) => {
    try {
      setLoading(true);
      let res;
      if (modalState.isCreate) {
        res = await createUser(userData);
      } else {
        res = await updateUser({
          ...userData,
          isBlock: false,
          isActive: true,
        });
      }
      if (!res.isError) {
        message.success(res.message);
        setModalState({ ...modalState, isOpen: false });
        await fetchUsers({
          pageIndex: 1,
          pageSize: 10,
        });
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.error("Error saving user:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeStatus = async (record, value) => {
    const valueStatus = {
      ...record,
      isBlock: !value,
    };

    try {
      const res = await updateUser(valueStatus);

      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
        return;
      }

      message.success("Cập nhật trạng thái thành công!");
      setListUser(
        listUser.map((u) =>
          u.id === res?.data?.id ? { ...u, isBlock: res?.data?.isBlock } : u
        )
      );
    } catch (error) {
      message.error(error);
    }
  };

  const columns = [
    {
      title: "Hình ảnh",
      dataIndex: "avataUrl",
      width: 100,
      render: (_, record) => {
        return (
          <Image
            style={{
              background: "#FF9900",
              borderRadius: 50,
            }}
            className="rounded-circle"
            width={30}
            preview={false}
            src={record?.avataUrl || null}
            fallback={avatar}
          />
        );
      },
    },
    {
      title: "Tài khoản",
      dataIndex: "userName",
    },
    {
      title: "Họ và tên",
      dataIndex: "fullName",
    },
    {
      title: "Ngày sinh",
      dataIndex: "dateOfBirth",
      render: (item) => (item ? moment(item + "z").format("DD/MM/YYYY") : "--"),
    },
    {
      title: "Email",
      dataIndex: "userEmail",
      render: (email) => (email ? email : "--"),
    },
    {
      title: "Điện thoại",
      dataIndex: "phoneNumber",
      render: (item) => (item ? item : "--"),
    },
    {
      title: "Trạng thái",
      dataIndex: "isBlock",
      render: (_, record) => {
        const disabled = !isRoot;

        return (
          <Tooltip
            title={`${
              record.isBlock !== true ? "Khóa tài khoản" : "Mở khóa tài khoản"
            }`}
          >
            <Switch
              disabled={disabled}
              size="small"
              checked={record.isBlock !== true}
              onChange={(e) => handleChangeStatus(record, e)}
            />
          </Tooltip>
        );
      },
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) => {
        const isOwner = userInfor?.id === record?.id;
        const disable = !isRoot && !isOwner;

        return (
          <Space>
            <Tooltip title={"Chỉnh sửa"}>
              <Button
                disabled={disable}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => handleEdit(record)}
              />
            </Tooltip>
            <Tooltip title={"Xóa"}>
              <Button
                disabled={disable}
                type="primary"
                shape="circle"
                danger
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => handleDelete(record)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <UserFilterForm
        onSearch={onSearch}
        createUser={() =>
          setModalState({
            isOpen: true,
            isCreate: true,
            buttonOk: "Tạo mới",
            editData: null,
            title: "Tạo nhân viên",
          })
        }
      />
      <TotalCount text="nhân viên" loading={loading} total={params.total} />
      <Divider />
      <DataTable
        columns={columns}
        dataSource={listUser.map((u) => ({ ...u, key: u.id })) || []}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={params.total}
        loading={loading}
        onPageChange={onchangePage}
      />
      <UserActionForm
        modalState={modalState}
        closeModal={() => setModalState({ ...modalState, isOpen: false })}
        actionUser={actionUser}
      />
    </div>
  );
};
export default Users;
