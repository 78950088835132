import React, { useEffect, useState } from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { Spin } from "antd";
import BasicLayout from "@/components/layouts/BasicLayout";
import AuthenticationLayout from "@/components/layouts/Authentication";
import Login from "@/page/Auth/Login";
import Error from "@/components/error/Error";
import { getAccessTokenBase, setAccessTokenBase } from "@/utils/common";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import UserProfile from "@/page/UserProfile/UserProfile";
import { routes } from "@/utils/const";
import Users from "@/page/Users";
import Topics from "@/page/Topics/Topics";
import Videos from "@/page/Videos";
import Types from "@/page/Types/Types";
import News from "@/page/News/News";
import Guides from "@/page/Guides";
import CreateNews from "@/page/News/CreateNews";
import NewsDetail from "@/page/News/NewsDetail";
import Images from "@/page/images";
import EditNews from "@/page/News/EditNews";
import GuideDetail from "@/page/Guides/GuideDetail";
import Introduce from "@/page/Introduce";
import CreateIntroduce from "@/page/Introduce/CreateIntroduce";
import DetailIntroduce from "@/page/Introduce/DetailIntroduce";
import UpdateIntroduce from "@/page/Introduce/UpdateIntroduce";
import Links from "@/page/Links";
import SubIntroduce from "@/page/Introduce/SubIntroduce";
import UpdateSubIntroduce from "@/page/Introduce/SubIntroduce/UpdateSubIntroduce";
import CreateSubIntroduce from "@/page/Introduce/SubIntroduce/CreateSubIntroduce";
import TitleInstruct from "@/page/TitleInstruct";
import TitleInstructDetail from "@/page/TitleInstruct/TitleInstructDetail";
import TitleInstructUpdate from "@/page/TitleInstruct/TitleInstructUpdate";
import TitleInstructCreate from "@/page/TitleInstruct/TitleInstructCreate";

const Providers = () => {
  const dataUser = useRecoilValue(infoUser);
  const userToken = getAccessTokenBase();
  const [firstCheck, setFirstCheck] = useState(false);
  const isRoot = dataUser?.isRoot;

  const routeList = [
    // {
    //   path: routes.HOME,
    //   element: "Home",
    //   allow: true,
    // },
    {
      path: routes.USERS,
      element: <Users />,
      allow: isRoot,
    },
    {
      path: routes.NEWS,
      element: <News />,
      allow: true,
    },
    {
      path: routes.NEWS_DETAIL,
      element: <NewsDetail />,
      allow: true,
    },
    {
      path: routes.CREATE_NEWS,
      element: <CreateNews />,
      allow: true,
    },
    {
      path: routes.EDIT_NEWS,
      element: <EditNews />,
      allow: true,
    },
    {
      path: routes.TYPE,
      element: <Types />,
      allow: isRoot,
    },
    {
      path: routes.TOPIC,
      element: <Topics />,
      allow: true,
    },
    {
      path: routes.VIDEO,
      element: <Videos />,
      allow: true,
    },
    {
      path: routes.IMAGES,
      element: <Images />,
      allow: true,
    },
    {
      path: routes.LINKS,
      element: <Links />,
      allow: true,
    },
    {
      path: routes.USER_PROFILE,
      element: <UserProfile />,
      allow: true,
    },
    {
      path: routes.GUIDES,
      element: <Guides />,
      allow: true,
    },
    {
      path: `${routes.GUIDES}/:id`,
      element: <GuideDetail />,
      allow: true,
    },
    {
      path: routes.INTRODUCE,
      element: <Introduce />,
      allow: true,
    },
    {
      path: routes.CREATE_INTRODUCE,
      element: <CreateIntroduce />,
      allow: true,
    },
    {
      path: routes.DETAIL_INTRODUCE,
      element: <DetailIntroduce />,
      allow: true,
    },
    {
      path: routes.UPDATE_INTRODUCE,
      element: <UpdateIntroduce />,
      allow: true,
    },
    {
      path: routes.SUB_INTRODUCE,
      element: <SubIntroduce />,
      allow: true,
    },
    {
      path: routes.SUB_UPDATE_INTRODUCE,
      element: <UpdateSubIntroduce />,
      allow: true,
    },
    {
      path: routes.SUB_CREATE_INTRODUCE,
      element: <CreateSubIntroduce />,
      allow: true,
    },

    {
      path: routes.TITLE_INSTRUCT,
      element: <TitleInstruct />,
      allow: true,
    },
    {
      path: routes.DETAIL_TITLE_INSTRUCT,
      element: <TitleInstructDetail />,
      allow: true,
    },
    {
      path: routes.UPDATE_TITLE_INSTRUCT,
      element: <TitleInstructUpdate />,
      allow: true,
    },
    {
      path: routes.CREATE_TITLE_INSTRUCT,
      element: <TitleInstructCreate />,
      allow: true,
    },
    {
      path: routes.ERROR,
      element: <Error />,
      allow: true,
    },
  ];

  const filteredRoutes = routeList.filter((route) => route.allow);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <BasicLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={isRoot ? "/users" : "/topics"} replace />,
        },
        {
          path: "*",
          element: <Navigate to={isRoot ? "/users" : "/topics"} replace />,
        },
        ...filteredRoutes,
      ],
    },
  ]);

  const authRouter = createBrowserRouter([
    {
      path: "/",
      element: <AuthenticationLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={routes.LOGIN} replace />,
        },
        {
          path: "*",
          element: <Navigate to={routes.LOGIN} replace />,
        },
        {
          path: routes.LOGIN,
          element: <Login />,
        },
        {
          path: routes.ERROR,
          element: <Error />,
        },
      ],
    },
  ]);

  useEffect(() => {
    const token = getAccessTokenBase();

    if (!!token) {
      setAccessTokenBase(token);
    }
    setFirstCheck(true);
  }, [dataUser]);

  if (!firstCheck) return null;

  return (
    <RouterProvider
      router={!!userToken ? router : authRouter}
      fallbackElement={<Spin size="large" />}
    />
  );
};

export default Providers;
