import React, { useCallback, useEffect, useState } from "react";
import { Button, Divider, Image, message, Modal, Space, Tooltip, Tag } from "antd";
import DataTable from "@/components/common/DataTable";
import moment from "moment";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import GuideFilterForm from "@/components/guides/GuideFilterForm";
import GuideActionForm from "@/components/guides/GuideActionForm";
import TotalCount from "@/components/common/TotalCount";
import { StatusResponse, routes } from "@/utils/const";
import {
  getListInstructs,
  deleteInstruct,
} from "@/services/base";
import { useNavigate } from "react-router-dom";

const typeDevices = [
  {
    id: 1,
    title: "Điện thoại",
  },
  {
    id: 2,
    title: "Máy tính",
  },
];

const Guides = () => {
  document.title = "Quản lý hướng dẫn";

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [modalState, setModalState] = useState({
    isOpen: false,
    isCreate: true,
    title: "Thêm mới video",
    buttonOk: "Thêm mới",
    editData: null,
  });
  const [params, setParams] = useState({
    textSearch: "",
    pageIndex: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    getInstructs();
  }, [params]);

  const handleViewDetails = useCallback((record) => {
    navigate(`${routes.GUIDES}/${record.id}`);
  }, []);

  const getInstructs = async () => {
    try {
      setLoading(true);
      const res = await getListInstructs({ ...params });

      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }

      setTotalPage(res?.pagination?.total);
      setVideoList(res.data);
      setLoading(false);
    } catch (error) {
      message.error(error);
      setLoading(false);
    }
  };

  const onSearch = (value) => {
    setParams((prev) => ({
      ...prev,
      ...value,
      pageIndex: 1,
    }));
  };

  const reloadData = () => {
    getInstructs();
  };

  const handleEdit = (record) => {
    setModalState({
      isOpen: true,
      isCreate: false,
      buttonOk: "Cập nhật",
      editData: {
        ...record,
      },
      title: "Chỉnh sửa hướng dẫn",
    });
  };

  const handleDelete = async (record) => {
    Modal.confirm({
      title: "Xác nhận xóa",
      content: `Bạn có chắc chắn muốn xóa hướng dẫn này"?`,
      okText: "Xóa",
      okType: "danger",
      cancelText: "Hủy",
      onOk: async () => {
        try {
          setLoading(true);
          const res = await deleteInstruct(record.id);
          if (!res.isError) {
            message.success("Xóa hướng dẫn thành công");
            reloadData();
          } else {
            message.error("Xóa hướng dẫn thất bại");
          }
        } catch (error) {
          message.error("Error deleting hướng dẫn.");
          console.error("Error deleting hướng dẫn:", error);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const onchangePage = (page, pageSize) => {
    setParams((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize,
    }));
  };

  const handleCloseModal = () => {
    setModalState({
      isOpen: false,
      isCreate: true,
      title: "Thêm mới video",
      buttonOk: "Thêm mới",
      editData: null,
    });
  };

  const columns = [
    {
      title: "Tiêu đề",
      dataIndex: "title",
    },
    {
      title: "Nội dung",
      dataIndex: "contentInstruct",
    },
    {
      title: "Hướng dẫn chính",
      dataIndex: "titleName",
      render: (value) => {
        return <Tag>{value}</Tag>;
      },
    },
    {
      title: "Thiết bị",
      dataIndex: "type",
      render: (value) => {
        const type = typeDevices.find(item => item.id == value)

        if (type) {
          return <Tag>{type.title}</Tag>;
        }
      },
    },
    {
      title: "Thời gian tạo",
      dataIndex: "createdDate",
      render: (value) => moment(value + "z").format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Thời gian cập nhật",
      dataIndex: "modifiedDate",
      render: (value) => moment(value + "z").format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) => {
        return (
          <div className="flex gap-2">
            <Tooltip title={"Xem chi tiết"}>
              <Button
                style={{
                  backgroundColor: "#FF9900",
                }}
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
                size="small"
                onClick={() => handleViewDetails(record)}
              />
            </Tooltip>
            <Tooltip title={"Chỉnh sửa"}>
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => handleEdit(record)}
              />
            </Tooltip>
            <Tooltip title={"Xóa"}>
              <Button
                type="primary"
                shape="circle"
                danger
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => handleDelete(record)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <GuideFilterForm
        onSearch={onSearch}
        createGuide={() =>
          setModalState({
            isOpen: true,
            isCreate: true,
            buttonOk: "Thêm mới",
            editData: null,
            title: "Thêm hướng dẫn",
          })
        }
      />
      <TotalCount
        text="hướng dẫn"
        loading={loading}
        total={totalPage ? totalPage : 0}
      />
      <Divider />
      <DataTable
        columns={columns}
        dataSource={videoList.map((u) => ({ ...u, key: u.id })) || []}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={totalPage}
        loading={loading}
        onPageChange={onchangePage}
      />
      <GuideActionForm
        modalState={modalState}
        closeModal={() => handleCloseModal()}
        reload={reloadData}
      />
    </div>
  );
};
export default Guides;
